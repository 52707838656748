import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './profile.state';
import { getProfileByIdThunk } from './profile.actions';
import { UserVerificationInterface } from '@types-declaration/entities.types';

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileVerification: (state, action: PayloadAction<UserVerificationInterface>) => {
      if (state.profile === null) return;
      state.profile.verification = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProfileByIdThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getProfileByIdThunk.fulfilled, (state, action) => {
        state.pending = false;
        state.profile = action.payload;
      })
      .addCase(getProfileByIdThunk.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || null;
      });
  },
});

export const { setProfileVerification } = profileSlice.actions;
export default profileSlice.reducer;
