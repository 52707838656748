import { FC } from 'react';
import { List, ListItem, Skeleton, Stack } from '@mui/material';

const PendingUserVerification: FC = () => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <List>
        <ListItem>
          <Skeleton variant="text" height={32} width={240} />
        </ListItem>
        <ListItem>
          <Skeleton variant="text" height={32} width={240} />
        </ListItem>
        <ListItem>
          <Skeleton variant="text" height={32} width={160} />
        </ListItem>
        <ListItem>
          <Skeleton variant="text" height={32} width={200} />
        </ListItem>
        <ListItem>
          <Skeleton variant="text" height={32} width={250} />
        </ListItem>
      </List>
      <Stack alignItems="center" spacing={2}>
        <Skeleton variant="text" height={24} width={132} />
        <Skeleton variant="rectangular" height={136} width={136} />
        <Skeleton variant="rounded" height={40} width={194} />
      </Stack>
    </Stack>
  );
};

export default PendingUserVerification;
