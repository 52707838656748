import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './subcategories.state';
import {
  createSubcategoryThunk,
  getSubcategoriesByCategoryIdThunk,
  updateSubcategoryThunk,
} from './subcategories.actions';

export const subcategoriesSlice = createSlice({
  name: 'subcategories',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSubcategoriesByCategoryIdThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getSubcategoriesByCategoryIdThunk.fulfilled, (state, action) => {
        state.pending = false;
        state.subcategories = action.payload;
      })
      .addCase(getSubcategoriesByCategoryIdThunk.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || null;
      })
      .addCase(createSubcategoryThunk.fulfilled, (state, action) => {
        action.payload.name = action.payload.translations[0].name;

        state.subcategories = [action.payload, ...state.subcategories];
      })
      .addCase(createSubcategoryThunk.rejected, (state, action) => {
        state.error = action.payload || null;
      })
      .addCase(updateSubcategoryThunk.fulfilled, (state, action) => {
        const index = state.subcategories.findIndex(({ id }) => id === action.payload.id);

        state.subcategories[index] = action.payload;
        state.subcategories[index].name = action.payload.translations[0].name;
      })
      .addCase(updateSubcategoryThunk.rejected, (state, action) => {
        state.error = action.payload || null;
      });
  },
});

export default subcategoriesSlice.reducer;
