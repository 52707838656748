import { useState } from 'react';

interface HookUseManageDialogReturnInterface {
  state: {
    open: boolean;
  };
  handlers: {
    handleOpen: () => void;
    handleClose: () => void;
  };
}

const useManageDialog = (): HookUseManageDialogReturnInterface => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  return {
    state: {
      open,
    },
    handlers: {
      handleOpen,
      handleClose,
    },
  };
};

export default useManageDialog;
