import axios from 'axios';

import { REST_COUNTRIES_API } from './rest-countries.constants';
import {
  GetAllCountriesArgumentsInterface,
  GetAllCountriesResponseType,
} from './rest-countries.types';

export const getAllCountriesRequest = async (
  args: GetAllCountriesArgumentsInterface,
): Promise<GetAllCountriesResponseType> => {
  const { query } = args;

  return (await axios.get(`${REST_COUNTRIES_API}/all`, { params: query })).data;
};
