import { AppStoreType } from '@config/redux-toolkit';
import { GetAllCategoriesResponseType } from '@services/api/category/category.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const selectCategories = (state: AppStoreType): GetAllCategoriesResponseType => {
  return state.categoriesReducer.categories;
};

export const selectCategoriesPending = (state: AppStoreType): boolean => {
  return state.categoriesReducer.pending;
};

export const selectCategoriesError = (state: AppStoreType): ResponseFailureInterface | null => {
  return state.categoriesReducer.error;
};
