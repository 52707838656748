import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Box, Grid, Typography } from '@mui/material';
import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';

import { useTopRatedPros } from '@pages/Home/hooks';
import { Avatar, Rating } from './TopRatedProSection.styles';

const TopRatedProsSection: FC = () => {
  const { t } = useTranslation('home');

  const { reviews } = useTopRatedPros();

  if (reviews.length === 0) return null;

  return (
    <Box component="section" m={4}>
      <Typography variant="h6" component="h3" mb={2}>
        {t('Top rated BEE')}
      </Typography>
      <Grid container spacing={3} justifyContent="space-around">
        {reviews.map((review) => {
          const { id, createdAt, rating, comment, recipient, creator } = review;

          return (
            <Grid key={id} item xs={12} sm={6} lg={3}>
              <Avatar
                alt={recipient.name}
                {...(recipient.photo !== null && { src: recipient.photo.thumbnailURL })}
              />
              <Rating
                value={rating}
                readOnly
                icon={<StarBorderPurple500OutlinedIcon color="primary" />}
                emptyIcon={<StarBorderPurple500OutlinedIcon color="disabled" />}
              />
              <Typography noWrap variant="h6" component="p" mb={4} textAlign="center">
                {recipient.name}
              </Typography>
              <Typography variant="body3" component="p" color="text.secondary">
                {t('Feedback by:')}
              </Typography>
              <Typography noWrap gutterBottom variant="body1" color="primary">
                {creator.name}
              </Typography>
              <Typography gutterBottom variant="body1">
                {comment}
              </Typography>
              <Typography variant="body3" component="p" color="text.secondary">
                {format(new Date(createdAt), 'dd MMM yyyy')}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TopRatedProsSection;
