import authAxiosInstance from '@config/axios/auth-instance';
import {
  GetAllTendersArgumentsInterface,
  GetAllTendersResponseType,
  GetTenderStatisticsArgumentsInterface,
  GetTenderStatisticsResponseType,
  GetTenderWithRelationsByIdArgumentsInterface,
  GetTenderWithRelationsByIdResponseType,
} from './tender.types';

export const getAllTendersRequest = async (
  args: GetAllTendersArgumentsInterface,
): Promise<GetAllTendersResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get('/tenders', { params: query });
};

export const getTenderWithRelationsByIdRequest = async (
  args: GetTenderWithRelationsByIdArgumentsInterface,
): Promise<GetTenderWithRelationsByIdResponseType> => {
  const { params, query } = args;

  return await authAxiosInstance.get(`/tenders/${params.id}/with-relations`, { params: query });
};

export const getTenderStatisticsRequest = async (
  args: GetTenderStatisticsArgumentsInterface,
): Promise<GetTenderStatisticsResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get('/tenders/statistics', { params: query });
};
