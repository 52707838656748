import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProfilesSection } from './components';
import HeaderSection from '@components/HeaderSection';

const Profiles: FC = () => {
  const { t } = useTranslation('profiles');

  return (
    <>
      <HeaderSection primary={t('All Users')} my={2} mx={4} />
      <ProfilesSection />
    </>
  );
};

export default Profiles;
