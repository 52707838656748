import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Stack, Typography } from '@mui/material';

import { AutocompleteGroup, AutocompleteInput, AutocompleteOption } from './components';
import Frame from '@components/Frame';
import LoadingButton from '@components/LoadingButton';
import { useCountryAllowlistAutocomplete } from '@pages/Settings/hooks';

const CountryAllowlistSection: FC = () => {
  const { t } = useTranslation('settings');

  const {
    state: { value, options, disabled, pending },
    handlers: { handleChange, handleSelectAll, handleSubmit },
  } = useCountryAllowlistAutocomplete();

  return (
    <Frame component="section" mx={4} mb={4}>
      <Typography component="h2" variant="subtitle2" mb={2}>
        {t('Configure country allowlist (phone number codes on sign-up page)')}
      </Typography>
      <Stack spacing={2} alignItems="flex-end">
        <Autocomplete
          multiple
          fullWidth
          disableCloseOnSelect
          options={options}
          disabled={disabled}
          value={value}
          limitTags={8}
          onChange={handleChange}
          getOptionLabel={(option) => option.name.common}
          groupBy={() => ''}
          renderGroup={(params) => (
            <AutocompleteGroup key={params.key} params={params} onClick={handleSelectAll} />
          )}
          renderOption={AutocompleteOption}
          renderInput={AutocompleteInput}
        />
        <LoadingButton loading={pending} variant="contained" size="large" onClick={handleSubmit}>
          {t('Save')}
        </LoadingButton>
      </Stack>
    </Frame>
  );
};

export default CountryAllowlistSection;
