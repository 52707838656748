import { FC } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { useSnackbarErrorMessage } from '@hooks';
import { useDataGridColumns, useUnverifiedUserVerifications } from '@pages/UserVerifications/hooks';
import {
  selectUserVerifications,
  selectUserVerificationsError,
  selectUserVerificationsPending,
} from '@services/store/user-verifications';
import { useAppSelector } from '@config/redux-toolkit';
import { GET_USER_VERIFICATIONS_PAGE_SIZE_OPTIONS } from '@constants/common.constants';

const UserVerificationsSection: FC = () => {
  const { t } = useTranslation('user-verifications');

  const pending = useAppSelector(selectUserVerificationsPending);
  const userVerifications = useAppSelector(selectUserVerifications);
  const error = useAppSelector(selectUserVerificationsError);

  useSnackbarErrorMessage(error?.message);

  const columns = useDataGridColumns();

  const {
    state: { queryParams },
    handlers: { handleSortModelChange, handlePaginationModelChange },
  } = useUnverifiedUserVerifications();

  return (
    <Box component="section" mx={4} mb={4}>
      <DataGrid
        rows={userVerifications.data}
        rowCount={userVerifications.totalRecords}
        columns={columns}
        loading={pending}
        sortingMode="server"
        paginationMode="server"
        disableRowSelectionOnClick
        pageSizeOptions={GET_USER_VERIFICATIONS_PAGE_SIZE_OPTIONS}
        onSortModelChange={handleSortModelChange}
        onPaginationModelChange={handlePaginationModelChange}
        paginationModel={{ pageSize: Number(queryParams.take), page: Number(queryParams.page) - 1 }}
        slotProps={{
          pagination: {
            labelRowsPerPage: t('Rows per page:'),
            labelDisplayedRows: ({ from, to, count }) => `${from}–${to} / ${count}`,
          },
        }}
      />
    </Box>
  );
};

export default UserVerificationsSection;
