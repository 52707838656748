import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TenderDetailsSection } from './components';
import HeaderSection from '@components/HeaderSection';
import { getTenderWithRelationsByIdThunk } from '@services/store/tender';
import { useAppDispatch } from '@config/redux-toolkit';

const TenderDetails: FC = () => {
  const { t } = useTranslation('tender-details');
  const { tenderId } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (typeof tenderId === 'string') {
      dispatch(
        getTenderWithRelationsByIdThunk({
          params: { id: tenderId },
          query: {
            relations: ['category', 'subcategory', 'location', 'client', 'files', 'offers.pro'],
          },
        }),
      );
    }
  }, [tenderId, dispatch]);

  return (
    <>
      <HeaderSection primary={t('Tender Details')} my={2} mx={4} />
      <TenderDetailsSection />
    </>
  );
};

export default TenderDetails;
