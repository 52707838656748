import { format, isSameDay, isSameMonth, isSameYear } from 'date-fns';

import { QueryParam } from '@enums/common.enums';
import { CREATE_TENDER_TIME_RANGES } from '@constants/common.constants';

export const getQueryFromSearchParams = (
  searchParams: URLSearchParams,
  queryParam: QueryParam,
  defaultValue: string,
): string => {
  const query = searchParams.get(queryParam);

  return query !== null ? query : defaultValue;
};

export const getSkipForPagination = (page: number, take: number): number => page * take - take;

export const debounce = <T extends (...args: any[]) => ReturnType<T>>(
  fn: T,
  timeout: number,
): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, timeout);
  };
};

export const timeRangeFormatter = (timeRange: string[]): string[] => {
  if (timeRange.length === 3) return ['8:00-22:00'];

  if (
    timeRange.includes(CREATE_TENDER_TIME_RANGES.FROM_8_TO_12) &&
    timeRange.includes(CREATE_TENDER_TIME_RANGES.FROM_12_TO_16)
  ) {
    return ['8:00-16:00'];
  }

  if (
    timeRange.includes(CREATE_TENDER_TIME_RANGES.FROM_12_TO_16) &&
    timeRange.includes(CREATE_TENDER_TIME_RANGES.FROM_16_TO_22)
  ) {
    return ['12:00-22:00'];
  }

  return timeRange;
};

export const dateRangeFormatter = (from: Date, to: Date): string => {
  from = new Date(from);
  to = new Date(to);

  if (isSameDay(from, to)) return format(from, 'dd MMM yyyy');
  if (isSameMonth(from, to)) return `${format(from, 'dd')} - ${format(to, 'dd MMM yyyy')}`;
  if (isSameYear(from, to)) return `${format(from, 'dd MMM')} - ${format(to, 'dd MMM yyyy')}`;
  return `${format(from, 'dd MMM yyyy')} - ${format(to, 'dd MMM yyyy')}`;
};
