import axios from 'axios';

import { BACKEND_URL } from '@constants/common.constants';
import {
  setContentLanguageHeaderRequestInterceptor,
  transformResponseInterceptor,
  transformErrorInterceptor,
} from './interceptors';

const baseAxiosInstance = axios.create({
  baseURL: `${BACKEND_URL}/api`,
  timeout: 5000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
});

baseAxiosInstance.interceptors.request.use(setContentLanguageHeaderRequestInterceptor);

baseAxiosInstance.interceptors.response.use(
  transformResponseInterceptor,
  transformErrorInterceptor,
);

export default baseAxiosInstance;
