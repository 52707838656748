import { AppStoreType } from '@config/redux-toolkit';
import { GetSubcategoriesByCategoryIdResponseType } from '@services/api/category/category.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const selectSubcategories = (
  state: AppStoreType,
): GetSubcategoriesByCategoryIdResponseType => {
  return state.subcategoriesReducer.subcategories;
};

export const selectSubcategoriesPending = (state: AppStoreType): boolean => {
  return state.subcategoriesReducer.pending;
};

export const selectSubcategoriesError = (state: AppStoreType): ResponseFailureInterface | null => {
  return state.subcategoriesReducer.error;
};
