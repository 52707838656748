import { TendersStateInterface } from './tenders.types';

export const initialState: TendersStateInterface = {
  tenders: {
    data: [],
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
  pending: false,
  error: null,
};
