import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useDataGridColumns = (): GridColDef[] => {
  const { t } = useTranslation('profiles');
  const navigate = useNavigate();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: t('Created At'),
        width: 130,
        valueFormatter: (params) => format(new Date(params.value), 'dd MMM yyyy'),
      },
      {
        field: 'name',
        headerName: t('Full Name'),
        flex: 1,
      },
      {
        field: 'email',
        headerName: t('Email'),
        flex: 2,
      },
      {
        field: 'emailVerifiedAt',
        headerName: t('Email Verified At'),
        width: 130,
        valueFormatter: (params) =>
          params.value !== null ? format(new Date(params.value), 'dd MMM yyyy') : t('Not verified'),
      },
      {
        field: 'id',
        headerName: t('Actions'),
        width: 80,
        sortable: false,
        renderCell: (params) => (
          <IconButton onClick={() => navigate(`/profiles/${params.value}`)}>
            <ArrowForwardIosIcon color="primary" />
          </IconButton>
        ),
      },
    ],
    [t, navigate],
  );

  return columns;
};

export default useDataGridColumns;
