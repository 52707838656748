import { ChartData } from 'chart.js';
import { useTranslation } from 'react-i18next';

import { blue, green, red, yellow } from '@config/mui/colors';
import {
  UserStatisticsInterface,
  TenderStatisticsInterface,
} from '@types-declaration/entities.types';

interface HookUseDoughnutDataReturnInterface {
  createUserRolesData: (
    userStatistics: UserStatisticsInterface,
  ) => ChartData<'doughnut', any[], string>;
  createTenderStatusesData: (
    tenderStatistics: TenderStatisticsInterface,
  ) => ChartData<'doughnut', any[], string>;
}

const useDoughnutData = (): HookUseDoughnutDataReturnInterface => {
  const { t } = useTranslation('home');

  const createUserRolesData = (
    userStatistics: UserStatisticsInterface,
  ): ChartData<'doughnut', any[], string> => {
    const { clientCount, proCount } = userStatistics;

    return {
      labels: [t('Client'), t('Bee')],
      datasets: [
        {
          label: t('Count'),
          data: [clientCount, proCount],
          backgroundColor: [blue[80], yellow[80]],
          borderColor: [blue[60], yellow[60]],
          borderWidth: 2,
        },
      ],
    };
  };

  const createTenderStatusesData = (
    tenderStatistics: TenderStatisticsInterface,
  ): ChartData<'doughnut', any[], string> => {
    const { newCount, acceptedCount, completedCount, canceledCount } = tenderStatistics;

    return {
      labels: [t('New'), t('Accepted'), t('Completed'), t('Canceled')],
      datasets: [
        {
          label: t('Count'),
          data: [newCount, acceptedCount, completedCount, canceledCount],
          backgroundColor: [green[80], yellow[80], green[30], red[80]],
          borderColor: [green[60], yellow[60], green[10], red[60]],
          borderWidth: 2,
        },
      ],
    };
  };

  return {
    createUserRolesData,
    createTenderStatusesData,
  };
};

export default useDoughnutData;
