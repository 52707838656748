import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { GridColDef } from '@mui/x-data-grid';

const useDataGridColumns = (): GridColDef[] => {
  const { t } = useTranslation('tender-details');

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: t('Created At'),
        width: 160,
        valueFormatter: (params) => format(new Date(params.value), 'dd MMM yyyy'),
      },
      {
        field: 'pro',
        headerName: t('Bee Full Name'),
        minWidth: 160,
        flex: 1,
        valueFormatter: (params) => params.value.name,
      },
      {
        field: 'acceptedAt',
        headerName: t('Accepted At'),
        width: 160,
        valueFormatter: (params) =>
          params.value === null ? t('Not accepted') : format(new Date(params.value), 'dd MMM yyyy'),
      },
    ],
    [t],
  );

  return columns;
};

export default useDataGridColumns;
