import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      paddingRight: 16,
      paddingLeft: 16,
      paddingBottom: 24,

      [theme.breakpoints.up('md')]: {
        paddingRight: 24,
        paddingLeft: 24,
      },
    }),
  },
};
