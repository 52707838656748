import { red } from '@config/mui/colors';

export default {
  styleOverrides: {
    root: {
      '&.Mui-error': {
        '& .MuiCheckbox-root': {
          color: red[40],
        },
      },
    },
  },
};
