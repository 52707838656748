import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BoxProps, Button, ButtonGroup, Stack, Typography } from '@mui/material';

import {
  DialogAcceptUserVerification,
  DialogRejectUserVerification,
  PendingUserVerification,
  UserVerificationDetails,
  ViewDocuments,
} from './components';
import Frame from '@components/Frame';
import Observer from '@components/Observer';
import { useUserVerification } from './hooks';
import { useManageDialog } from '@hooks';
import { UserVerificationInterface } from '@types-declaration/entities.types';

type Props = {
  id: string;
  ns: string;
  onSuccessAccept?: (userVerification: UserVerificationInterface) => void;
  onSuccessReject?: (userVerification: UserVerificationInterface) => void;
} & BoxProps;

const ProcessUserVerification: FC<Props> = (props) => {
  const { id, ns, onSuccessAccept, onSuccessReject, ...rest } = props;

  const { t } = useTranslation(ns);

  const { userVerification, pending } = useUserVerification(id);

  const {
    state: { open: acceptDialogOpen },
    handlers: { handleOpen: handleOpenAcceptDialog, handleClose: handleCloseAcceptDialog },
  } = useManageDialog();
  const {
    state: { open: rejectDialogOpen },
    handlers: { handleOpen: handleOpenRejectDialog, handleClose: handleCloseRejectDialog },
  } = useManageDialog();

  return (
    <>
      <Frame component="section" p={4} {...rest}>
        <Typography variant="h6" component="p" paragraph>
          {t('User Verification Inquiry')}
        </Typography>
        <Observer
          empty={userVerification === null}
          pending={pending}
          PendingComponent={<PendingUserVerification />}
        >
          {userVerification !== null && (
            <Stack direction="row" justifyContent="space-between">
              <UserVerificationDetails ns={ns} userVerification={userVerification} />
              <Stack alignItems="center" spacing={2}>
                <ViewDocuments ns={ns} files={[userVerification.photo]} />
                <ButtonGroup>
                  <Button variant="contained" color="error" onClick={handleOpenRejectDialog}>
                    {t('Reject')}
                  </Button>
                  <Button variant="contained" onClick={handleOpenAcceptDialog}>
                    {t('Approve')}
                  </Button>
                </ButtonGroup>
              </Stack>
            </Stack>
          )}
        </Observer>
      </Frame>
      {userVerification !== null && (
        <>
          <DialogAcceptUserVerification
            ns={ns}
            id={userVerification.id}
            open={acceptDialogOpen}
            onClose={handleCloseAcceptDialog}
            onSuccessSubmit={onSuccessAccept}
          />
          <DialogRejectUserVerification
            ns={ns}
            id={userVerification.id}
            open={rejectDialogOpen}
            onClose={handleCloseRejectDialog}
            onSuccessSubmit={onSuccessReject}
          />
        </>
      )}
    </>
  );
};

export default ProcessUserVerification;
