import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Box, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';

import { TotalCountCard } from './components';
import { useBarData, useDoughnutData, useStatistics } from '@pages/Home/hooks';
import { StatisticsSelect } from '@enums/common.enums';

Chart.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const DashboardSection: FC = () => {
  const { t } = useTranslation('home');

  const {
    state: { select, from, userStatistics, tenderStatistics },
    handlers: { handleSelectChange },
  } = useStatistics();

  const { createUserRolesData, createTenderStatusesData } = useDoughnutData();
  const { createUsersData, createTendersData } = useBarData();

  return (
    <Box component="section" mx={4} my={2}>
      <Typography variant="body2">{t('Select a time range for statistics')}</Typography>
      <Select value={select} fullWidth onChange={handleSelectChange}>
        <MenuItem value={StatisticsSelect.LAST_WEEK}>{t('Last Week')}</MenuItem>
        <MenuItem value={StatisticsSelect.LAST_MONTH}>{t('Last Month')}</MenuItem>
        <MenuItem value={StatisticsSelect.LAST_THREE_MONTH}>{t('Last Three Month')}</MenuItem>
        <MenuItem value={StatisticsSelect.LAST_SIX_MONTH}>{t('Last Six Month')}</MenuItem>
      </Select>
      <Typography variant="h6" component="h2" mt={3} mb={2}>
        {t('Total count for selected period')}
      </Typography>
      <Stack direction="row" spacing={4}>
        {userStatistics !== null && (
          <TotalCountCard value={userStatistics.totalCount} label={t('Users count:')} from={from} />
        )}
        {tenderStatistics !== null && (
          <TotalCountCard
            value={tenderStatistics.totalCount}
            label={t('Tenders count:')}
            from={from}
          />
        )}
      </Stack>
      <Typography variant="h6" component="h3" mt={3}>
        {t('Users statistics')}
      </Typography>
      {userStatistics !== null && (
        <Grid container spacing={2} alignItems="center" mt={2}>
          <Grid item xs={12} md={3}>
            <Doughnut data={createUserRolesData(userStatistics)} options={{ responsive: true }} />
          </Grid>
          <Grid item xs={12} md={9}>
            <Bar options={{ responsive: true }} data={createUsersData(userStatistics)} />
          </Grid>
        </Grid>
      )}
      <Typography variant="h6" component="h3" mt={3}>
        {t('Tenders statistics')}
      </Typography>
      {tenderStatistics !== null && (
        <Grid container spacing={2} alignItems="center" mt={2}>
          <Grid item xs={12} md={3}>
            <Doughnut
              data={createTenderStatusesData(tenderStatistics)}
              options={{ responsive: true }}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Line options={{ responsive: true }} data={createTendersData(tenderStatistics)} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DashboardSection;
