import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteRenderGroupParams, Button, Divider } from '@mui/material';

type Props = {
  params: AutocompleteRenderGroupParams;
  onClick: () => void;
};

const AutocompleteGroup: FC<Props> = (props) => {
  const { params, onClick } = props;

  const { t } = useTranslation('settings');

  return (
    <>
      <Button variant="outlined" fullWidth onClick={onClick}>
        {t('Select all')}
      </Button>
      <Divider sx={{ my: 1 }} />
      {params.children}
    </>
  );
};

export default AutocompleteGroup;
