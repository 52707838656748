import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import Frame from '@components/Frame';
import { useAppSelector } from '@config/redux-toolkit';
import { selectSubcategories } from '@services/store/subcategories';
import { selectCategory } from '@services/store/category';
import { SubcategoryInterface } from '@types-declaration/entities.types';

type Props = {
  onClick: (subcategory: SubcategoryInterface) => void;
};

const SubcategoryCards: FC<Props> = (props) => {
  const { onClick } = props;

  const { t } = useTranslation('category-details');

  const subcategories = useAppSelector(selectSubcategories);
  const category = useAppSelector(selectCategory);

  if (subcategories.length === 0) {
    return (
      <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" mt={6}>
        <WarningIcon color="warning" />
        <Typography variant="h6" component="h3">
          {t('This category has no subcategories')}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <Typography variant="h6" component="h3" mt={3} mb={2}>
        {t("Category's subcategories:", { category: category?.name })}
      </Typography>
      <Grid container spacing={2}>
        {subcategories.map((subcategory) => {
          const { id, name } = subcategory;

          return (
            <Grid key={id} item xs={12} md={6} lg={4}>
              <Frame display="flex">
                <Stack flex={1} mr={2}>
                  <Typography variant="subtitle3" component="p" color="secondary">
                    {t('Subcategory name')}
                  </Typography>
                  <Typography variant="body1">{name}</Typography>
                </Stack>
                <IconButton sx={{ alignSelf: 'center' }} onClick={() => onClick(subcategory)}>
                  <ModeEditIcon color="primary" />
                </IconButton>
              </Frame>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SubcategoryCards;
