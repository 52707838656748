import { Box, IconButton, alpha, styled } from '@mui/material';

import { grey } from '@config/mui/colors';

export const Root = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  padding: '64px 16px',
  zIndex: 10000,
  backgroundColor: alpha(grey[10], 0.2),
  overflow: 'hidden',

  [theme.breakpoints.up('md')]: {
    padding: '64px 200px',
  },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: -64,
  right: -16,
  zIndex: 200,

  '& svg': {
    width: 32,
    height: 32,
    color: grey[10],
  },

  [theme.breakpoints.up('md')]: {
    top: -48,
    right: 0,
  },
}));

export const SkipButton = styled(IconButton)(({ theme }) => ({
  display: 'none',

  '& svg': {
    width: 48,
    height: 48,
  },

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 200,
    height: '100%',
    zIndex: 100,
    borderRadius: 0,
  },
}));

export const ImageWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',

  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});
