import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Chip, Divider, Stack, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';

import Frame from '@components/Frame';
import MediaGallery from '@components/MediaGallery';
import TenderStatus from '@components/TenderStatus';
import { useAppSelector } from '@config/redux-toolkit';
import { selectTender } from '@services/store/tender';
import { dateRangeFormatter, timeRangeFormatter } from '@utils/common.utils';
import { TenderCreator, Card } from './TenderGeneral.styles';

const TenderGeneral: FC = () => {
  const tender = useAppSelector(selectTender);

  const { t } = useTranslation('tender-details');
  const navigate = useNavigate();

  if (tender === null) return null;

  const {
    title,
    description,
    status,
    from,
    to,
    time,
    client,
    files,
    location,
    category,
    subcategory,
  } = tender;
  const { id, photo, name } = client;
  const { country, city, street, postcode } = location;

  const handleTenderCreatorClick = (): void => navigate(`/profiles/${id}`);

  return (
    <Stack spacing={2}>
      <Frame>
        <TenderCreator onClick={handleTenderCreatorClick}>
          <Avatar {...(photo !== null && { src: photo.thumbnailURL })} alt={name} />
          <Typography variant="body1">{name}</Typography>
        </TenderCreator>
        <Typography variant="subtitle2" gutterBottom>
          {title}
        </Typography>
        {Boolean(description) && (
          <Typography variant="body1" gutterBottom>
            {description}
          </Typography>
        )}
        <Stack direction="row" spacing={2} mt={2}>
          <LocationOnIcon color="primary" />
          <Typography variant="body1">
            {country}, {city}, {street}, {postcode}
          </Typography>
        </Stack>
      </Frame>
      <Frame>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem variant="middle" />}
        >
          <Card>
            <TenderStatus status={status} />
          </Card>
          <Card>
            <Chip label={category.name} variant="outlined" />
          </Card>
          <Card>
            <Chip label={subcategory.name} variant="outlined" />
          </Card>
          <Card>
            <CalendarMonthOutlinedIcon />
            <Typography variant="caption1" sx={{ m: 1 }}>
              {Boolean(from) && Boolean(to) ? dateRangeFormatter(from, to) : t('Any date')}
            </Typography>
          </Card>
          <Card>
            <ScheduleOutlinedIcon />
            <Typography variant="caption1" sx={{ m: 1 }}>
              {time?.length > 0 ? timeRangeFormatter(time) : t('Any time')}
            </Typography>
          </Card>
        </Stack>
      </Frame>
      {files.length > 0 && (
        <Frame>
          <MediaGallery files={files} />
        </Frame>
      )}
    </Stack>
  );
};

export default TenderGeneral;
