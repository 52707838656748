import { styled, Box } from '@mui/material';

import { HIDDEN_NAVIGATION_WIDTH, NAVIGATION_WIDTH } from '@constants/navigation.constants';

type RootProps = {
  navigationOpen: boolean;
  disableMarginLeft: boolean;
};

export const Root = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'navigationOpen' && prop !== 'disableMarginLeft',
})<RootProps>(({ navigationOpen, disableMarginLeft }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  transition: 'margin 0.2s ease',
  marginLeft: HIDDEN_NAVIGATION_WIDTH,

  ...(navigationOpen && {
    marginLeft: NAVIGATION_WIDTH,
  }),

  ...(disableMarginLeft && {
    marginLeft: 0,
  }),
}));
