import { HTMLAttributes } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { RestCountryInterface } from '@services/rest-countries/v3.1/rest-countries.types';

const AutocompleteOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: RestCountryInterface,
): JSX.Element => {
  const FLAG_CDN_API = 'https://flagcdn.com';
  const lowercaseISOCode = option.cca2.toLowerCase();

  return (
    <MenuItem {...props}>
      <ListItemIcon>
        <img
          loading="lazy"
          width={20}
          srcSet={`${FLAG_CDN_API}/w40/${lowercaseISOCode}.png 2x`}
          src={`${FLAG_CDN_API}/w20/${lowercaseISOCode}.png`}
          alt={`${option.name.common} flag`}
        />
      </ListItemIcon>
      <ListItemText>
        {option.name.common} ({option.cca2})
      </ListItemText>
    </MenuItem>
  );
};

export default AutocompleteOption;
