import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import Providers from '@layout/Providers';
import Layout from '@layout/Layout';
import Auth from '@layout/Auth';
import Routes from '@layout/Routes';
import '@config/i18n';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Suspense fallback={<p>Loading...</p>}>
    <Providers>
      <Auth>
        <Layout>
          <Routes />
        </Layout>
      </Auth>
    </Providers>
  </Suspense>,
);
