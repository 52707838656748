import { FileRejection } from 'react-dropzone';

import { CommonTransformationOptions } from '@types-declaration/cloudinary.types';

export const setTransformationOptionsToFormData = (
  formData: FormData,
  options: CommonTransformationOptions,
): void => {
  Object.entries(options).forEach(([key, value]) => {
    formData.append(`transformation[${key}]`, value);
  });
};

export const dropzoneErrorsParser = (files: FileRejection[]): string[] => {
  return Array.from(
    new Set([
      ...files
        .map((file) => file.errors)
        .flat(1)
        .map((error) => error.message),
    ]),
  );
};
