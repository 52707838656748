export default {
  10: '#1F1F1F',
  20: '#353535',
  30: '#4B4B4B',
  40: '#636363',
  50: '#7C7C7C',
  60: '#969696',
  70: '#B1B1B1',
  80: '#CCCCCC',
  90: '#E8E8E8',
  95: '#F7F7F7',
  99: '#FFFFFF',
};
