import { UserReviewsStateInterface } from './user-reviews.types';

export const initialState: UserReviewsStateInterface = {
  userReviews: {
    data: [],
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
  pending: false,
  error: null,
};
