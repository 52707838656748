import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    paper: ({ theme }: { theme: Theme }) => ({
      width: 'calc(100% - 16px)',
      maxHeight: 'calc(100% - 16px)',
      margin: 8,
      borderRadius: 28,

      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 48px)',
        maxHeight: 'calc(100% - 48px)',
        margin: 24,
      },
    }),
  },
};
