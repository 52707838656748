import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './category.state';
import { getCategoryByIdThunk, updateCategoryThunk } from './category.actions';

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCategoryByIdThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getCategoryByIdThunk.fulfilled, (state, action) => {
        state.pending = false;
        state.category = action.payload;
      })
      .addCase(getCategoryByIdThunk.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || null;
      })
      .addCase(updateCategoryThunk.fulfilled, (state, action) => {
        state.category = action.payload;
        state.category.name = action.payload.translations[0].name;
      })
      .addCase(updateCategoryThunk.rejected, (state, action) => {
        state.error = action.payload || null;
      });
  },
});

export default categorySlice.reducer;
