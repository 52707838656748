import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { GridColDef } from '@mui/x-data-grid';
import { IconButton, Rating } from '@mui/material';
import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';
import WorkIcon from '@mui/icons-material/Work';

const useDataGridColumns = (): GridColDef[] => {
  const { t } = useTranslation('tenders');
  const navigate = useNavigate();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: t('Created At'),
        width: 130,
        valueFormatter: (params) => format(new Date(params.value), 'dd MMM yyyy'),
      },
      {
        field: 'rating',
        headerName: t('Rating'),
        width: 140,
        renderCell: (params) => (
          <Rating
            value={params.value}
            readOnly
            icon={<StarBorderPurple500OutlinedIcon color="primary" />}
            emptyIcon={<StarBorderPurple500OutlinedIcon color="disabled" />}
          />
        ),
      },
      {
        field: 'comment',
        headerName: t('Feedback'),
        flex: 3,
      },
      {
        field: 'creator',
        headerName: t('Review Creator'),
        minWidth: 160,
        flex: 1,
        valueFormatter: (params) => params.value.name,
      },
      {
        field: 'tender',
        headerName: t('Tender'),
        width: 100,
        sortable: false,
        renderCell: (params) => (
          <IconButton onClick={() => navigate(`/tenders/${params.value.id}`)}>
            <WorkIcon color="primary" />
          </IconButton>
        ),
      },
    ],
    [t, navigate],
  );

  return columns;
};

export default useDataGridColumns;
