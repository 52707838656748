import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { Tabs } from './components';
import SearchFieldWithDebounce from '@components/SearchFieldWithDebounce';
import { useSnackbarErrorMessage } from '@hooks';
import { useDataGridColumns, useUsers } from '@pages/Profiles/hooks';
import { useAppSelector } from '@config/redux-toolkit';
import { selectUsers, selectUsersError, selectUsersPending } from '@services/store/users';
import { GET_ALL_USERS_PAGE_SIZE_OPTIONS } from '@constants/common.constants';

const ProfilesSection: FC = () => {
  const { t } = useTranslation('profiles');

  const pending = useAppSelector(selectUsersPending);
  const users = useAppSelector(selectUsers);
  const error = useAppSelector(selectUsersError);

  useSnackbarErrorMessage(error?.message);

  const columns = useDataGridColumns();

  const {
    state: { queryParams },
    handlers: {
      handleTabChange,
      handleSearchChange,
      handleSortModelChange,
      handlePaginationModelChange,
    },
  } = useUsers();

  return (
    <Box component="section" mx={4} mb={4}>
      {queryParams.tab && <Tabs value={queryParams.tab} onChange={handleTabChange} />}
      <SearchFieldWithDebounce
        onChange={handleSearchChange}
        placeholder={t("Search by users's first and last name")}
        fullWidth
        sx={{ mb: 2 }}
      />
      <DataGrid
        rows={users.data}
        rowCount={users.totalRecords}
        columns={columns}
        loading={pending}
        sortingMode="server"
        paginationMode="server"
        disableRowSelectionOnClick
        pageSizeOptions={GET_ALL_USERS_PAGE_SIZE_OPTIONS}
        onSortModelChange={handleSortModelChange}
        onPaginationModelChange={handlePaginationModelChange}
        paginationModel={{ pageSize: Number(queryParams.take), page: Number(queryParams.page) - 1 }}
        slotProps={{
          pagination: {
            labelRowsPerPage: t('Rows per page:'),
            labelDisplayedRows: ({ from, to, count }) => `${from}–${to} / ${count}`,
          },
        }}
      />
    </Box>
  );
};

export default ProfilesSection;
