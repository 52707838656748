import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './tenders.state';
import { getAllTendersThunk } from './tenders.actions';

export const tendersSlice = createSlice({
  name: 'tenders',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllTendersThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(getAllTendersThunk.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.tenders = action.payload;
      })
      .addCase(getAllTendersThunk.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || null;
      });
  },
});

export default tendersSlice.reducer;
