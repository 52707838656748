import { FC } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import Home from '@pages/Home';
import Categories from '@pages/Categories';
import CategoryDetails from '@pages/CategoryDetails';
import Tenders from '@pages/Tenders';
import TenderDetails from '@pages/TenderDetails';
import Profiles from '@pages/Profiles';
import ProfileDetails from '@pages/ProfileDetails';
import Preview from '@pages/Preview';
import UserVerifications from '@pages/UserVerifications';
import Settings from '@pages/Settings';

const PrivateRoutes: FC = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/categories" element={<Categories />} />
    <Route path="/categories/:categoryId" element={<CategoryDetails />} />
    <Route path="/tenders" element={<Tenders />} />
    <Route path="/tenders/:tenderId" element={<TenderDetails />} />
    <Route path="/profiles" element={<Profiles />} />
    <Route path="/profiles/:userId" element={<ProfileDetails />} />
    <Route path="/user-verifications" element={<UserVerifications />} />
    <Route path="/settings" element={<Settings />} />
    <Route path="/preview" element={<Preview />} />
    <Route path="/sign-in" element={<Navigate to="/" />} />
    <Route path="*" element={<p>404 | Page Not Found</p>} />
  </Routes>
);

export default PrivateRoutes;
