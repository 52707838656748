import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Divider, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Logo from '@components/Logo';
import LocaleSwitcher from '@components/LocaleSwitcher';
import ProfileMenu from '@components/ProfileMenu';
import { NAVIGATION_ITEMS } from '@constants/navigation.constants';
import { Drawer, Box, ButtonGroup, Button } from './Navigation.styles';

type Props = {
  open: boolean;
  onOpen: () => void;
};

const Navigation: FC<Props> = (props) => {
  const { open, onOpen } = props;

  const { t } = useTranslation('common');

  return (
    <Drawer open={open} variant="permanent">
      <Box>
        {open && <Logo />}
        <IconButton onClick={onOpen}>
          {open ? <NavigateBeforeIcon color="primary" /> : <NavigateNextIcon color="primary" />}
        </IconButton>
      </Box>
      <ButtonGroup orientation="vertical" variant="text">
        {NAVIGATION_ITEMS.map(({ route, i18nKeyLabel, Icon }, index) => (
          <Button component={Link} to={route} key={index}>
            <Icon />
            {open && <Typography variant="body2">{t(i18nKeyLabel)}</Typography>}
          </Button>
        ))}
      </ButtonGroup>
      <LocaleSwitcher showLocale={open} />
      <Divider />
      <ProfileMenu showFullName={open} />
    </Drawer>
  );
};

export default Navigation;
