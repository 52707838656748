import { Middleware } from '@reduxjs/toolkit';

import { signInThunk, refreshTokenThunk, logoutThunk } from './auth.actions';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@constants/common.constants';

const authLocalStorageMiddleware: Middleware = () => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case signInThunk.fulfilled.type:
    case refreshTokenThunk.fulfilled.type: {
      const { accessToken, refreshToken } = action.payload;

      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      break;
    }

    case refreshTokenThunk.rejected.type:
    case logoutThunk.fulfilled.type:
    case logoutThunk.rejected.type: {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      break;
    }

    default:
      break;
  }

  return result;
};

export default authLocalStorageMiddleware;
