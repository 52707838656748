import { AppStoreType } from '@config/redux-toolkit';
import { QueryResultInterface, ResponseFailureInterface } from '@types-declaration/common.types';
import { UserVerificationInterface } from '@types-declaration/entities.types';

export const selectUserVerifications = (
  state: AppStoreType,
): QueryResultInterface<UserVerificationInterface> => {
  return state.userVerificationsReducer.userVerifications;
};

export const selectUserVerificationsPending = (state: AppStoreType): boolean => {
  return state.userVerificationsReducer.pending;
};

export const selectUserVerificationsError = (
  state: AppStoreType,
): ResponseFailureInterface | null => {
  return state.userVerificationsReducer.error;
};
