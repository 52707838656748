import { useTranslation } from 'react-i18next';
import { ChartData } from 'chart.js';
import { format } from 'date-fns';

import { blue, green, yellow } from '@config/mui/colors';
import {
  TenderStatisticsInterface,
  UserStatisticsInterface,
} from '@types-declaration/entities.types';

interface HookUseBarDataReturnInterface {
  createUsersData: (userStatistics: UserStatisticsInterface) => ChartData<'bar', any[], string>;
  createTendersData: (
    tenderStatistics: TenderStatisticsInterface,
  ) => ChartData<'line', any[], string>;
}

const useBarData = (): HookUseBarDataReturnInterface => {
  const { t } = useTranslation('home');

  const createUsersData = (
    userStatistics: UserStatisticsInterface,
  ): ChartData<'bar', any[], string> => {
    return {
      labels: userStatistics.clientChartCount.map((item: any) =>
        format(new Date(item.date), 'dd MMM yyyy'),
      ),
      datasets: [
        {
          label: t('Client'),
          data: userStatistics.clientChartCount.map((item: any) => item.count),
          backgroundColor: blue[80],
        },
        {
          label: t('Bee'),
          data: userStatistics.proChartCount.map((item: any) => item.count),
          backgroundColor: yellow[80],
        },
      ],
    };
  };

  const createTendersData = (
    tenderStatistics: TenderStatisticsInterface,
  ): ChartData<'line', any[], string> => {
    return {
      labels: tenderStatistics.tendersChartCount.map((item: any) =>
        format(new Date(item.date), 'dd MMM yyyy'),
      ),
      datasets: [
        {
          label: t('Count'),
          data: tenderStatistics.tendersChartCount.map((item: any) => item.count),
          borderColor: green[40],
          backgroundColor: green[60],
        },
      ],
    };
  };

  return {
    createUsersData,
    createTendersData,
  };
};

export default useBarData;
