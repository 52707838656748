import { createAsyncThunk } from '@reduxjs/toolkit';

import { getSubcategoriesByCategoryIdRequest } from '@services/api/category/category.api';
import {
  GetSubcategoriesByCategoryIdArgumentsInterface,
  GetSubcategoriesByCategoryIdResponseType,
} from '@services/api/category/category.types';
import {
  createSubcategoryRequest,
  updateSubcategoryRequest,
} from '@services/api/subcategory/subcategory.api';
import {
  CreateSubcategoryArgumentsInterface,
  CreateSubcategoryResponseType,
  UpdateSubcategoryArgumentsInterface,
  UpdateSubcategoryResponseType,
} from '@services/api/subcategory/subcategory.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const getSubcategoriesByCategoryIdThunk = createAsyncThunk<
  GetSubcategoriesByCategoryIdResponseType,
  GetSubcategoriesByCategoryIdArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>(
  'subcategories/getSubcategoriesByCategoryIdThunk',
  async (args, { fulfillWithValue, rejectWithValue }) => {
    try {
      return fulfillWithValue(await getSubcategoriesByCategoryIdRequest(args));
    } catch (error) {
      return rejectWithValue(error as ResponseFailureInterface);
    }
  },
);

export const createSubcategoryThunk = createAsyncThunk<
  CreateSubcategoryResponseType,
  CreateSubcategoryArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('subcategories/createSubcategoryThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await createSubcategoryRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});

export const updateSubcategoryThunk = createAsyncThunk<
  UpdateSubcategoryResponseType,
  UpdateSubcategoryArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('subcategories/updateSubcategoryThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await updateSubcategoryRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});
