import { FC } from 'react';

import { Outlet, Navigate } from 'react-router-dom';

type Props = {
  authenticated: boolean;
};

const PublicRoute: FC<Props> = (props) => {
  const { authenticated } = props;

  if (authenticated) return <Navigate to="/" />;
  return <Outlet />;
};

export default PublicRoute;
