import { InternalAxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

import { ACCESS_TOKEN_KEY, CONTENT_LANGUAGE_KEY } from '@constants/common.constants';

export const setAuthorizationHeaderRequestInterceptor = (
  config: InternalAxiosRequestConfig<any>,
): InternalAxiosRequestConfig<any> => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  if (accessToken !== null) config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};

export const setContentLanguageHeaderRequestInterceptor = (
  config: InternalAxiosRequestConfig<any>,
): InternalAxiosRequestConfig<any> => {
  const lang = localStorage.getItem(CONTENT_LANGUAGE_KEY);

  if (lang !== null) config.headers['Content-Language'] = lang;
  return config;
};

export const transformResponseInterceptor = (response: AxiosResponse): any => response.data;

export const transformErrorInterceptor = (error: AxiosError): any => {
  throw error.response?.data;
};
