import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CountryAllowlistSection } from './components';
import HeaderSection from '@components/HeaderSection';

const Settings: FC = () => {
  const { t } = useTranslation('settings');

  return (
    <>
      <HeaderSection primary={t('Settings')} my={2} mx={4} />
      <CountryAllowlistSection />
    </>
  );
};

export default Settings;
