import { AppStoreType } from '@config/redux-toolkit';
import { GetAllTendersResponseType } from '@services/api/tender/tender.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const selectTenders = (state: AppStoreType): GetAllTendersResponseType => {
  return state.tendersReducer.tenders;
};

export const selectTendersPending = (state: AppStoreType): boolean => {
  return state.tendersReducer.pending;
};

export const selectTendersError = (state: AppStoreType): ResponseFailureInterface | null => {
  return state.tendersReducer.error;
};
