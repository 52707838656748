import { green, khaki, red } from '@config/mui/colors';

export default {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: {
      fontSize: 12,
      transform: 'none',
    },
    outlined: {
      color: khaki[30],

      '&.Mui-focused': {
        color: green[10],
      },

      '&.Mui-error': {
        color: red[40],
      },
    },
  },
};
