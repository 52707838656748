import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { getAllCountriesRequest } from '@services/rest-countries/v3.1/rest-countries.api';
import { RestCountryInterface } from '@services/rest-countries/v3.1/rest-countries.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

interface HookUseCountriesInterface {
  state: {
    countries: RestCountryInterface[];
    pending: boolean;
  };
  functions: {
    filterCountriesByISOCode: (ISOCodes: string[]) => RestCountryInterface[];
  };
}

const useCountries = (): HookUseCountriesInterface => {
  const { enqueueSnackbar } = useSnackbar();

  const [countries, setCountries] = useState<RestCountryInterface[]>([]);
  const [pending, setPending] = useState<boolean>(false);

  const filterCountriesByISOCode = useCallback(
    (ISOCodes: string[]): RestCountryInterface[] => {
      return countries.filter((country) => ISOCodes.includes(country.cca2));
    },
    [countries],
  );

  const getCountries = useCallback(async () => {
    try {
      setPending(true);

      const response = await getAllCountriesRequest({ query: { fields: 'name,cca2,idd' } });
      setCountries(response);
    } catch (error) {
      const { message } = error as ResponseFailureInterface;

      if (message !== null) enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setPending(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  return {
    state: {
      countries,
      pending,
    },
    functions: {
      filterCountriesByISOCode,
    },
  };
};

export default useCountries;
