import { FC } from 'react';

import { Outlet, Navigate } from 'react-router-dom';

type Props = {
  authenticated: boolean;
};

const PrivateRoute: FC<Props> = (props) => {
  const { authenticated } = props;

  if (authenticated) return <Outlet />;
  return <Navigate to="/sign-in" />;
};

export default PrivateRoute;
