import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

import LoadingButton from '@components/LoadingButton';
import { useRejectUserVerification } from '../../hooks';
import { UserVerificationInterface } from '@types-declaration/entities.types';

type Props = {
  ns: string;
  id: string;
  open: boolean;
  onClose: () => void;
  onSuccessSubmit?: (userVerification: UserVerificationInterface) => void;
};

interface RejectUserVerificationFieldValuesInterface {
  reason: string;
}

const DialogRejectUserVerification: FC<Props> = (props) => {
  const { ns, id, open, onClose, onSuccessSubmit } = props;

  const { t } = useTranslation(ns);

  const {
    state: { pending },
    functions: { rejectUserVerification },
  } = useRejectUserVerification({ ns, onSuccessSubmit });

  const { register, handleSubmit } = useForm<RejectUserVerificationFieldValuesInterface>();

  const submit: SubmitHandler<RejectUserVerificationFieldValuesInterface> = async (fieldValues) => {
    const { reason } = fieldValues;
    await rejectUserVerification({ params: { id }, body: { reason } });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('Are you sure you want to REJECT this verification inquiry?')}</DialogTitle>
      <DialogContent>
        <Typography paragraph>
          {t(
            'User will receive a message about his verification rejection. You can write down a reason of rejection (optional).',
          )}
        </Typography>
        <Box
          id="dialog-reject-user-verification-form"
          component="form"
          onSubmit={handleSubmit(submit)}
        >
          <TextField
            placeholder={t('Reason')}
            fullWidth
            multiline
            rows={3}
            {...register('reason')}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          type="submit"
          form="dialog-reject-user-verification-form"
          variant="contained"
          color="error"
          loading={pending}
        >
          {t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogRejectUserVerification;
