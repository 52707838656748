import { styled, Typography as MuiTypography } from '@mui/material';

import LoadingButtonComponent from '@components/LoadingButton';

export const LoadingButton = styled(LoadingButtonComponent)(({ theme }) => ({
  alignSelf: 'flex-end',
  width: '100%',
  marginTop: 32,

  [theme.breakpoints.up('sm')]: {
    width: 'unset',
  },
}));

export const Typography = styled(MuiTypography)({
  marginBottom: 32,
  textAlign: 'center',
}) as typeof MuiTypography;
