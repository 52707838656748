import { useCallback, useEffect } from 'react';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';

import { useDataGridHandlers, useQueryParams } from '@hooks';
import { getAllTendersThunk } from '@services/store/tenders';
import { useAppDispatch } from '@config/redux-toolkit';
import {
  QUERY_PARAM_PAGE_DEFAULT_VALUE,
  QUERY_PARAM_SEARCH_DEFAULT_VALUE,
  QUERY_PARAM_TAB_DEFAULT_VALUE,
} from '@constants/common.constants';
import { QueryParamsInterface } from '@types-declaration/common.types';

interface HookUseTendersReturnInterface {
  state: {
    queryParams: QueryParamsInterface;
  };
  handlers: {
    handleTabChange: (_: React.SyntheticEvent, value: string) => void;
    handleSearchChange: (search: string) => void;
    handleSortModelChange: (sortModel: GridSortModel) => void;
    handlePaginationModelChange: (paginationModel: GridPaginationModel) => void;
  };
}

const useTenders = (): HookUseTendersReturnInterface => {
  const dispatch = useAppDispatch();

  const {
    state: { queryParams },
    handlers: { setQueryParams, getQuery },
  } = useQueryParams({
    defaultQueryParams: {
      tabValue: QUERY_PARAM_TAB_DEFAULT_VALUE,
      searchValue: QUERY_PARAM_SEARCH_DEFAULT_VALUE,
    },
  });

  const { handleSortModelChange, handlePaginationModelChange } = useDataGridHandlers({
    queryParams,
    setQueryParams,
  });

  const handleTabChange = (_: React.SyntheticEvent, value: string): void => {
    setQueryParams((previousState) => ({
      ...previousState,
      page: QUERY_PARAM_PAGE_DEFAULT_VALUE,
      tab: value,
    }));
  };

  const handleSearchChange = useCallback(
    (search: string): void => {
      setQueryParams((previousState) => ({
        ...previousState,
        page: QUERY_PARAM_PAGE_DEFAULT_VALUE,
        search,
      }));
    },
    [setQueryParams],
  );

  useEffect(() => {
    const query = getQuery();
    dispatch(getAllTendersThunk({ query: { ...query, ...(query.tab && { status: query.tab }) } }));
  }, [dispatch, getQuery]);

  return {
    state: {
      queryParams,
    },
    handlers: {
      handleTabChange,
      handleSearchChange,
      handleSortModelChange,
      handlePaginationModelChange,
    },
  };
};

export default useTenders;
