import { FC, ReactNode, useState } from 'react';

import Navigation from '@components/Navigation';
import { useAppSelector } from '@config/redux-toolkit';
import { selectUser } from '@services/store/auth';
import { Root } from './Layout.styles';

type Props = {
  children: ReactNode;
};

const Layout: FC<Props> = (props) => {
  const { children } = props;

  const user = useAppSelector(selectUser);

  const [navigationOpen, setNavigationOpen] = useState<boolean>(true);

  const handleNavigationOpen = (): void => setNavigationOpen((previousState) => !previousState);

  return (
    <>
      {user !== null && <Navigation open={navigationOpen} onOpen={handleNavigationOpen} />}
      <Root navigationOpen={navigationOpen} disableMarginLeft={user === null}>
        <main>{children}</main>
      </Root>
    </>
  );
};

export default Layout;
