import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';

import { useDataGridHandlers, useQueryParams } from '@hooks';
import { SortDirection } from '@enums/common.enums';
import { getUserReviewsThunk } from '@services/store/user-reviews';
import { useAppDispatch } from '@config/redux-toolkit';
import { QueryParamsInterface } from '@types-declaration/common.types';

interface HookUseProfileReviewsReturnInterface {
  state: {
    queryParams: QueryParamsInterface;
  };
  handlers: {
    handleSortModelChange: (sortModel: GridSortModel) => void;
    handlePaginationModelChange: (paginationModel: GridPaginationModel) => void;
  };
}

const useProfileReviews = (): HookUseProfileReviewsReturnInterface => {
  const { userId } = useParams();

  const dispatch = useAppDispatch();

  const {
    state: { queryParams },
    handlers: { setQueryParams, getQuery },
  } = useQueryParams({ defaultQueryParams: { sortDirectionValue: SortDirection.DESC } });

  const { handleSortModelChange, handlePaginationModelChange } = useDataGridHandlers({
    queryParams,
    setQueryParams,
  });

  useEffect(() => {
    const query = getQuery();

    if (typeof userId === 'string') {
      dispatch(getUserReviewsThunk({ params: { id: userId }, query }));
    }
  }, [userId, getQuery, dispatch]);

  return {
    state: {
      queryParams,
    },
    handlers: {
      handleSortModelChange,
      handlePaginationModelChange,
    },
  };
};

export default useProfileReviews;
