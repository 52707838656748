import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { QueryParam, SortDirection } from '@enums/common.enums';
import { getQueryFromSearchParams, getSkipForPagination } from '@utils/common.utils';
import {
  QUERY_PARAM_PAGE_DEFAULT_VALUE,
  QUERY_PARAM_SORT_BY_DEFAULT_VALUE,
  QUERY_PARAM_TAKE_DEFAULT_VALUE,
} from '@constants/common.constants';
import { QueryInterface, QueryParamsInterface } from '@types-declaration/common.types';

interface DefaultQueryParamsInterface {
  takeValue?: string;
  pageValue?: string;
  sortByValue?: string;
  sortDirectionValue?: string;
  tabValue?: string;
  searchValue?: string;
}

interface HookUseQueryParamsArgumentsInterface {
  defaultQueryParams: DefaultQueryParamsInterface;
}

interface HookUseQueryParamsReturnInterface {
  state: {
    queryParams: QueryParamsInterface;
  };
  handlers: {
    setQueryParams: Dispatch<SetStateAction<QueryParamsInterface>>;
    getQuery: () => QueryInterface;
  };
}

const useQueryParams = (
  args: HookUseQueryParamsArgumentsInterface,
): HookUseQueryParamsReturnInterface => {
  const { defaultQueryParams } = args;

  const {
    takeValue = QUERY_PARAM_TAKE_DEFAULT_VALUE,
    pageValue = QUERY_PARAM_PAGE_DEFAULT_VALUE,
    sortByValue = QUERY_PARAM_SORT_BY_DEFAULT_VALUE,
    sortDirectionValue = SortDirection.ASC,
    tabValue = null,
    searchValue = null,
  } = defaultQueryParams;

  const [searchParams, setSearchParams] = useSearchParams();

  const [queryParams, setQueryParams] = useState<QueryParamsInterface>({
    take: takeValue,
    page: getQueryFromSearchParams(searchParams, QueryParam.PAGE, pageValue),
    sortBy: getQueryFromSearchParams(searchParams, QueryParam.SORT_BY, sortByValue),
    sortDirection: getQueryFromSearchParams(
      searchParams,
      QueryParam.SORT_DIRECTION,
      sortDirectionValue,
    ),
    ...(tabValue && { tab: getQueryFromSearchParams(searchParams, QueryParam.TAB, tabValue) }),
    ...(searchValue && {
      search: getQueryFromSearchParams(searchParams, QueryParam.SEARCH, searchValue),
    }),
  });

  const getQuery = useCallback((): QueryInterface => {
    const params = new URLSearchParams(searchParams);

    const { take, page, sortBy, sortDirection, tab, search } = queryParams;

    if (page !== pageValue) {
      params.set(QueryParam.PAGE, page);
    } else {
      params.delete(QueryParam.PAGE);
    }

    if (sortBy !== sortByValue) {
      params.set(QueryParam.SORT_BY, sortBy);
    } else {
      params.delete(QueryParam.SORT_BY);
    }

    if (sortDirection !== sortDirectionValue) {
      params.set(QueryParam.SORT_DIRECTION, sortDirection);
    } else {
      params.delete(QueryParam.SORT_DIRECTION);
    }

    if (tab && tab !== tabValue) {
      params.set(QueryParam.TAB, tab);
    } else {
      params.delete(QueryParam.TAB);
    }

    if (search && search !== searchValue) {
      params.set(QueryParam.SEARCH, search);
    } else {
      params.delete(QueryParam.SEARCH);
    }

    setSearchParams(params);

    return {
      skip: getSkipForPagination(Number(page), Number(take)),
      take: Number(take),
      sortBy,
      sortDirection,
      ...(tab && tab !== tabValue && { tab }),
      ...(search && search !== searchValue && { search }),
    };
  }, [
    pageValue,
    searchParams,
    searchValue,
    sortByValue,
    sortDirectionValue,
    tabValue,
    queryParams,
    setSearchParams,
  ]);

  return {
    state: {
      queryParams,
    },
    handlers: {
      setQueryParams,
      getQuery,
    },
  };
};

export default useQueryParams;
