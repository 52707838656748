import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';

import LoadingButton from '@components/LoadingButton';
import { useCreateSubcategory } from '@pages/CategoryDetails/hooks';

type Props = {
  open: boolean;
  onClose: () => void;
};

const DialogCreateSubcategory: FC<Props> = (props) => {
  const { open, onClose } = props;

  const { t } = useTranslation('category-details');

  const {
    state: { fields, control, pending },
    handlers: { handleSubmit },
  } = useCreateSubcategory({ onSuccessSubmit: onClose });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>{t('Create Subcategory')}</DialogTitle>
      <DialogContent>
        <Stack
          spacing={2}
          id="dialog-create-subcategory-form"
          component="form"
          onSubmit={handleSubmit}
        >
          {fields.map((field, index) => (
            <Controller
              key={field.id}
              control={control}
              name={`translations.${index}.name`}
              render={(data) => (
                <TextField
                  {...data.field}
                  required
                  fullWidth
                  placeholder={t('Subcategory name - locale', { locale: field.locale })}
                />
              )}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={pending}
          type="submit"
          form="dialog-create-subcategory-form"
          variant="contained"
        >
          {t('Create')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCreateSubcategory;
