import { FC, useCallback, useState } from 'react';

import MediaViewer from './MediaViewer';
import { FileInterface } from '@types-declaration/entities.types';
import { ImageWrapper, Root } from './MediaGallery.styles';

type Props = {
  files: FileInterface[];
};

const MediaGallery: FC<Props> = (props) => {
  const { files } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeMediaIndex, setActiveMediaIndex] = useState<number>(0);

  const handleOpenMediaViewer = (index: number): void => {
    setActiveMediaIndex(index);
    setIsOpen(true);
  };

  const handleCloseMediaViewer = (): void => setIsOpen(false);

  const handlePrevMedia = useCallback((): void => {
    setActiveMediaIndex((previousState) =>
      previousState - 1 >= 0 ? previousState - 1 : files.length - 1,
    );
  }, [files.length]);

  const handleNextMedia = useCallback((): void => {
    setActiveMediaIndex((previousState) =>
      previousState + 1 !== files.length ? previousState + 1 : 0,
    );
  }, [files.length]);

  if (files.length === 0) return null;

  return (
    <>
      <Root>
        {files.map((file, index) => (
          <ImageWrapper key={file.id} onClick={() => handleOpenMediaViewer(index)}>
            <img src={file.thumbnailURL} width={136} height={136} alt={file.filename} />
          </ImageWrapper>
        ))}
      </Root>
      {isOpen && (
        <MediaViewer
          files={files}
          activeIndex={activeMediaIndex}
          onClose={handleCloseMediaViewer}
          onPrev={handlePrevMedia}
          onNext={handleNextMedia}
        />
      )}
    </>
  );
};

export default MediaGallery;
