import { alpha, Theme } from '@mui/material';

import { green, grey, khaki } from '@config/mui/colors';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    elevated: true;
  }
}

export default {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
    sizeMedium: {
      lineHeight: 1.4,
      letterSpacing: 0.1,
      paddingTop: 10,
      paddingRight: 24,
      paddingBottom: 10,
      paddingLeft: 24,
    },
    contained: ({ theme }: { theme: Theme }) => ({
      '&:hover': {
        boxShadow: theme.shadows[1],
      },

      '&.Mui-focusVisible': {
        boxShadow: 'none',
      },

      '&:active': {
        boxShadow: 'none',
      },
    }),
    containedPrimary: {
      '&:hover': {
        backgroundColor: alpha(green[40], 0.96),
      },

      '&.Mui-focusVisible': {
        backgroundColor: alpha(green[40], 0.93),
      },

      '&:active': {
        backgroundColor: alpha(green[40], 0.93),
      },
    },
    containedSecondary: {
      color: green[10],
      backgroundColor: alpha(grey[80], 0.8),

      '&:hover': {
        backgroundColor: alpha(grey[80], 0.96),
      },

      '&.Mui-focusVisible': {
        backgroundColor: grey[80],
      },

      '&:active': {
        backgroundColor: grey[80],
      },
    },
    outlinedPrimary: {
      borderColor: khaki[50],

      '&:hover': {
        backgroundColor: grey[95],
      },

      '&.Mui-focusVisible': {
        borderColor: green[40],
        backgroundColor: alpha(grey[90], 0.6),
      },

      '&:active': {
        backgroundColor: alpha(grey[90], 0.6),
      },
    },
    textPrimary: {
      '&:hover': {
        backgroundColor: grey[95],
      },

      '&.Mui-focusVisible': {
        backgroundColor: alpha(grey[90], 0.6),
      },

      '&:active': {
        backgroundColor: alpha(grey[90], 0.6),
      },
    },
    elevated: ({ theme }: { theme: Theme }) => ({
      boxShadow: theme.shadows[1],

      '&:hover': {
        boxShadow: theme.shadows[2],
      },

      '&:disabled': {
        boxShadow: 'none',
        backgroundColor: theme.palette.action.disabledBackground,
      },
    }),
    elevatedPrimary: {
      color: green[40],
      backgroundColor: grey[99],

      '&:hover': {
        backgroundColor: alpha(grey[95], 0.7),
      },

      '&.Mui-focusVisible': {
        backgroundColor: grey[95],
      },

      '&:active': {
        backgroundColor: grey[95],
      },
    },
  },
};
