import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Chip as MuiChip, Typography } from '@mui/material';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import StarIcon from '@mui/icons-material/Star';
import ChatIcon from '@mui/icons-material/Chat';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import Frame from '@components/Frame';
import ProcessUserVerification from '@components/Features/ProcessUserVerification';
import { useAppDispatch, useAppSelector } from '@config/redux-toolkit';
import { selectProfile, setProfileVerification } from '@services/store/profile';
import { UserVerificationInterface } from '@types-declaration/entities.types';
import { Avatar, Chip } from './ProfileGeneral.styles';

const renderVerifiedIcon = (isVerified: boolean): ReactNode => {
  if (isVerified) return <VerifiedOutlinedIcon color="primary" />;
  return <WarningIcon color="warning" />;
};

const ProfileGeneral: FC = () => {
  const { t } = useTranslation('profile-details');

  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);

  if (profile === null) return null;

  const {
    role,
    name,
    email,
    emailVerifiedAt,
    photo,
    subscription,
    userLocation,
    summary,
    reviewsAverageRating,
    reviewsCount,
    verification,
  } = profile;

  const isEmailVerified = emailVerifiedAt !== null;
  const isUserVerificationSectionVisible =
    verification !== null && verification.verifiedAt === null && verification.photo !== null;

  const updateProfileUserVerification = (userVerification: UserVerificationInterface): void => {
    dispatch(setProfileVerification(userVerification));
  };

  return (
    <>
      <Frame p={4}>
        <Stack spacing={3}>
          <Avatar alt={name} {...(Boolean(photo?.fileURL) && { src: photo?.fileURL as string })} />
          <Box>
            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
              <Typography variant="h3" component="p">
                {name}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mt={2}>
              <MuiChip variant="outlined" color="primary" label={t(role)} />
              <Stack direction="row" spacing={1}>
                <StarIcon color="primary" />
                <Typography>{reviewsAverageRating?.toFixed(1) || 0}</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <ChatIcon color="primary" />
                <Typography>{t('reviews', { value: reviewsCount })}</Typography>
              </Stack>
            </Stack>
            <Typography variant="subtitle3" component="p" color="text.secondary" mt={2} mb={1}>
              {t('Email')}
            </Typography>
            <Typography variant="h6" component="p">
              {email}
            </Typography>
            <Stack direction="row" spacing={1} mt={1}>
              <Typography>{isEmailVerified ? t('Verified') : t('Not Verified')}</Typography>
              {renderVerifiedIcon(isEmailVerified)}
            </Stack>
            <Typography variant="subtitle3" component="p" color="text.secondary" mt={2} mb={1}>
              {t('Subscription')}
            </Typography>
            {subscription !== null ? (
              <Chip variant="filled" color="primary" label={subscription.subscription?.name} />
            ) : (
              <Chip variant="filled" label={t('No Subscription')} />
            )}
            <Typography variant="subtitle3" component="p" color="text.secondary" mt={2} mb={1}>
              {t('Location')}
            </Typography>
            <Stack direction="row" spacing={2}>
              <LocationOnIcon color="primary" />
              {userLocation !== null ? (
                <>
                  <Typography variant="body1">
                    {userLocation.location.country}, {userLocation.location.city},{' '}
                    {userLocation.location.street}, {userLocation.location.postcode}
                  </Typography>
                </>
              ) : (
                <Typography variant="body1">{t('Not Selected')}</Typography>
              )}
            </Stack>
            {summary !== null && (
              <>
                <Typography variant="subtitle3" component="p" color="text.secondary" mt={2} mb={1}>
                  {t('About')}
                </Typography>
                <Typography variant="body1">{summary.content}</Typography>
              </>
            )}
          </Box>
        </Stack>
      </Frame>
      {isUserVerificationSectionVisible && (
        <ProcessUserVerification
          mt={4}
          id={verification.id}
          ns="profile-details"
          onSuccessAccept={updateProfileUserVerification}
          onSuccessReject={updateProfileUserVerification}
        />
      )}
    </>
  );
};

export default ProfileGeneral;
