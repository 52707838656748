import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUserReviewsRequest } from '@services/api/user/user.api';
import {
  GetUserReviewsArgumentsInterface,
  GetUserReviewsResponseType,
} from '@services/api/user/user.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const getUserReviewsThunk = createAsyncThunk<
  GetUserReviewsResponseType,
  GetUserReviewsArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('user-reviews/getUserReviewsThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await getUserReviewsRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});
