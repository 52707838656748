import authAxiosInstance from '@config/axios/auth-instance';
import {
  CreateSubcategoryArgumentsInterface,
  CreateSubcategoryResponseType,
  UpdateSubcategoryArgumentsInterface,
  UpdateSubcategoryResponseType,
} from './subcategory.types';

export const createSubcategoryRequest = (
  args: CreateSubcategoryArgumentsInterface,
): Promise<CreateSubcategoryResponseType> => {
  const { body } = args;

  return authAxiosInstance.post('/subcategories', body);
};

export const updateSubcategoryRequest = (
  args: UpdateSubcategoryArgumentsInterface,
): Promise<UpdateSubcategoryResponseType> => {
  const { params, body } = args;

  return authAxiosInstance.put(`/subcategories/${params.id}`, body);
};
