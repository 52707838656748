import { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';

import { ProfileAdditional, ProfileGeneral, ProfileReviews } from './components';
import { useSnackbarErrorMessage } from '@hooks';
import { useAppSelector } from '@config/redux-toolkit';
import { selectProfileError } from '@services/store/profile';
import { ProfileDetailsPageTabs } from '@enums/common.enums';

const ProfileDetailsSection: FC = () => {
  const { t } = useTranslation('profile-details');

  const error = useAppSelector(selectProfileError);

  useSnackbarErrorMessage(error?.message);

  const [tab, setTab] = useState<ProfileDetailsPageTabs>(ProfileDetailsPageTabs.GENERAL);

  const handleTabChange = (_: SyntheticEvent, tab: ProfileDetailsPageTabs): void => setTab(tab);

  return (
    <Box component="section" mb={4} mx={4}>
      <Tabs value={tab} onChange={handleTabChange} sx={{ mb: 4 }}>
        <Tab value={ProfileDetailsPageTabs.GENERAL} label={t('General')} />
        <Tab value={ProfileDetailsPageTabs.REVIEWS} label={t('Reviews')} />
        <Tab value={ProfileDetailsPageTabs.ADDITIONAL} label={t('Additional')} />
      </Tabs>
      {tab === ProfileDetailsPageTabs.GENERAL && <ProfileGeneral />}
      {tab === ProfileDetailsPageTabs.REVIEWS && <ProfileReviews />}
      {tab === ProfileDetailsPageTabs.ADDITIONAL && <ProfileAdditional />}
    </Box>
  );
};

export default ProfileDetailsSection;
