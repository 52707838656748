import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivateRoutes from './PrivateRoutes';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import SignIn from '@pages/SignIn';
import { useAppSelector } from '@config/redux-toolkit';
import { selectUser } from '@services/store/auth';

const RoutesProvider: FC = () => {
  const user = useAppSelector(selectUser);

  const authenticated = user !== null;

  return (
    <Routes>
      <Route element={<PublicRoute authenticated={authenticated} />}>
        <Route path="/sign-in" element={<SignIn />} />
      </Route>
      <Route element={<PrivateRoute authenticated={authenticated} />}>
        <Route path="*" element={<PrivateRoutes />} />
      </Route>
    </Routes>
  );
};

export default RoutesProvider;
