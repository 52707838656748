import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAllTendersRequest } from '@services/api/tender/tender.api';
import {
  GetAllTendersResponseType,
  GetAllTendersArgumentsInterface,
} from '@services/api/tender/tender.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const getAllTendersThunk = createAsyncThunk<
  GetAllTendersResponseType,
  GetAllTendersArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('tenders/getAllTendersThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await getAllTendersRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});
