import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CategoryDetailsSection } from './components';
import HeaderSection from '@components/HeaderSection';
import { useSnackbarErrorMessage } from '@hooks';
import { useAppDispatch, useAppSelector } from '@config/redux-toolkit';
import {
  getSubcategoriesByCategoryIdThunk,
  selectSubcategoriesError,
} from '@services/store/subcategories';
import { getCategoryByIdThunk, selectCategoryError } from '@services/store/category';

const CategoryDetails: FC = () => {
  const { t } = useTranslation('category-details');
  const { categoryId } = useParams();

  const dispatch = useAppDispatch();

  const categoryError = useAppSelector(selectCategoryError);
  const subcategoriesError = useAppSelector(selectSubcategoriesError);

  useSnackbarErrorMessage(categoryError?.message);
  useSnackbarErrorMessage(subcategoriesError?.message);

  const getCategoryWithSubcategories = useCallback(async () => {
    if (typeof categoryId === 'string') {
      await dispatch(getCategoryByIdThunk({ params: { id: categoryId } }));
      await dispatch(getSubcategoriesByCategoryIdThunk({ params: { categoryId } }));
    }
  }, [categoryId, dispatch]);

  useEffect(() => {
    getCategoryWithSubcategories();
  }, [getCategoryWithSubcategories]);

  return (
    <>
      <HeaderSection primary={t('Category Details')} my={2} mx={4} />
      <CategoryDetailsSection />
    </>
  );
};

export default CategoryDetails;
