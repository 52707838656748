import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { MAX_SIZE_PHOTO_FILE_IN_BYTES } from '@constants/common.constants';
import { LoadingIndicator, Dropzone } from './DropzoneContentAndActions.styles';

type Props = {
  error: string | null;
  uploadPending: boolean;
  destroyPending: boolean;
  onAcceptedFile: (files: File[]) => void;
  onRejectedFile: (files: FileRejection[]) => void;
};

const DropzoneContentAndActions: FC<Props> = (props) => {
  const { error, uploadPending, destroyPending, onAcceptedFile, onRejectedFile } = props;

  const { t } = useTranslation('common');

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: MAX_SIZE_PHOTO_FILE_IN_BYTES,
    accept: { 'image/*': [] },
    onDropAccepted: onAcceptedFile,
    onDropRejected: onRejectedFile,
    disabled: uploadPending || destroyPending,
  });

  return (
    <Box position="relative">
      {uploadPending && <LoadingIndicator />}
      <Dropzone {...getRootProps()}>
        <>
          <AddIcon fontSize="large" />
          <Typography variant="body1" color="secondary" sx={{ ml: 2 }}>
            {t('Add photo')}
          </Typography>
        </>
        <input {...getInputProps()} />
      </Dropzone>
      <Typography variant="body3" sx={{ alignSelf: 'flex-start', mt: 1 }}>
        {t('You can also drag and drop file on this field')}
      </Typography>
      {Boolean(error) && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default DropzoneContentAndActions;
