import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import categoriesEN from '@locales/en/categories.json';
import categoryDetailsEN from '@locales/en/category-details.json';
import commonEN from '@locales/en/common.json';
import homeEN from '@locales/en/home.json';
import profileDetailsEN from '@locales/en/profile-details.json';
import profilesEN from '@locales/en/profiles.json';
import settingsEN from '@locales/en/settings.json';
import signInEN from '@locales/en/sign-in.json';
import tenderDetailsEN from '@locales/en/tender-details.json';
import tendersEN from '@locales/en/tenders.json';
import userVerificationsEN from '@locales/en/user-verifications.json';

import categoriesDE from '@locales/de/categories.json';
import categoryDetailsDE from '@locales/de/category-details.json';
import commonDE from '@locales/de/common.json';
import homeDE from '@locales/de/home.json';
import profileDetailsDE from '@locales/de/profile-details.json';
import profilesDE from '@locales/de/profiles.json';
import settingsDE from '@locales/de/settings.json';
import signInDE from '@locales/de/sign-in.json';
import tenderDetailsDE from '@locales/de/tender-details.json';
import tendersDE from '@locales/de/tenders.json';
import userVerificationsDE from '@locales/de/user-verifications.json';

import { CONTENT_LANGUAGE_KEY } from '@constants/common.constants';

export const locales = ['en', 'de'];

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    defaultNS: 'common',
    fallbackLng: 'en',
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
      lookupLocalStorage: CONTENT_LANGUAGE_KEY,
    },
    resources: {
      en: {
        ['categories']: categoriesEN,
        ['category-details']: categoryDetailsEN,
        ['common']: commonEN,
        ['home']: homeEN,
        ['profile-details']: profileDetailsEN,
        ['profiles']: profilesEN,
        ['settings']: settingsEN,
        ['sign-in']: signInEN,
        ['tender-details']: tenderDetailsEN,
        ['tenders']: tendersEN,
        ['user-verifications']: userVerificationsEN,
      },
      de: {
        ['categories']: categoriesDE,
        ['category-details']: categoryDetailsDE,
        ['common']: commonDE,
        ['home']: homeDE,
        ['profile-details']: profileDetailsDE,
        ['profiles']: profilesDE,
        ['settings']: settingsDE,
        ['sign-in']: signInDE,
        ['tender-details']: tenderDetailsDE,
        ['tenders']: tendersDE,
        ['user-verifications']: userVerificationsDE,
      },
    },
  });

export default i18n;
