declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    body3: true;
    caption1: true;
    caption2: true;
    caption3: true;
    caption: false;
    button: false;
    overline: false;
  }
}

export default {
  fontFamily: 'Inter, sans-serif',
  h1: {
    fontWeight: 300,
    fontSize: 57,
    lineHeight: '64px',
  },
  h2: {
    fontWeight: 500,
    fontSize: 45,
    lineHeight: '52px',
  },
  h3: {
    fontWeight: 500,
    fontSize: 36,
    lineHeight: '44px',
  },
  h4: {
    fontWeight: 500,
    fontSize: 32,
    lineHeight: '40px',
  },
  h5: {
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '36px',
  },
  h6: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '32px',
  },
  subtitle1: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '28px',
  },
  subtitle2: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.15,
  },
  subtitle3: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.1,
  },
  body1: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.5,
  },
  body2: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.25,
  },
  body3: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0.4,
  },
  caption1: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.1,
  },
  caption2: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0.5,
  },
  caption3: {
    fontWeight: 500,
    fontSize: 11,
    lineHeight: '16px',
    letterSpacing: 0.5,
  },
  button: {
    textTransform: 'none' as const,
  },
};
