import { UsersStateInterface } from './users.types';

export const initialState: UsersStateInterface = {
  users: {
    data: [],
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
  pending: false,
  error: null,
};
