import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';

import DropzoneContentAndActions from '@components/DropzoneContentAndActions';
import PreviewPhotoContentAndActions from '@components/PreviewPhotoContentAndActions';
import LoadingButton from '@components/LoadingButton';
import { useCreateCategory } from '@pages/Categories/hooks';

type Props = {
  open: boolean;
  onClose: () => void;
};

const DialogCreateCategory: FC<Props> = (props) => {
  const { open, onClose } = props;

  const { t } = useTranslation('categories');

  const {
    state: { file, uploadPending, destroyPending, submitPending, error, fields, control },
    handlers: { handleAcceptedFile, handleDestroyFile, handleRejectedFile, handleSubmit },
  } = useCreateCategory({ onSuccessSubmit: onClose });

  const showDropzoneContentAndActions = file === null;
  const showPreviewContentAndActions = file !== null;

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>{t('Create a category')}</DialogTitle>
      <DialogContent>
        <Stack
          spacing={2}
          id="dialog-create-category-form"
          component="form"
          onSubmit={handleSubmit}
        >
          {showDropzoneContentAndActions && (
            <DropzoneContentAndActions
              error={error}
              uploadPending={uploadPending}
              destroyPending={destroyPending}
              onAcceptedFile={handleAcceptedFile}
              onRejectedFile={handleRejectedFile}
            />
          )}
          {showPreviewContentAndActions && (
            <PreviewPhotoContentAndActions
              file={file}
              destroyPending={destroyPending}
              onDestroyFile={handleDestroyFile}
            />
          )}
          {fields.map((field, index) => (
            <Controller
              key={field.id}
              control={control}
              name={`translations.${index}.name`}
              render={(data) => (
                <TextField
                  {...data.field}
                  required
                  fullWidth
                  placeholder={t('Category name - locale', { locale: field.locale })}
                />
              )}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={submitPending}
          type="submit"
          form="dialog-create-category-form"
          variant="contained"
        >
          {t('Create')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCreateCategory;
