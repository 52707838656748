import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import WorkIcon from '@mui/icons-material/Work';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BadgeIcon from '@mui/icons-material/Badge';
import SettingsIcon from '@mui/icons-material/Settings';

export const NAVIGATION_WIDTH = 240;
export const HIDDEN_NAVIGATION_WIDTH = 72;

export const NAVIGATION_ITEMS = [
  {
    route: '/',
    i18nKeyLabel: 'Home',
    Icon: HomeIcon,
  },
  {
    route: '/categories',
    i18nKeyLabel: 'Categories',
    Icon: CategoryIcon,
  },
  {
    route: '/tenders',
    i18nKeyLabel: 'Tenders',
    Icon: WorkIcon,
  },
  {
    route: '/profiles',
    i18nKeyLabel: 'Profiles',
    Icon: PeopleAltIcon,
  },
  {
    route: '/user-verifications',
    i18nKeyLabel: 'Verifications',
    Icon: BadgeIcon,
  },
  {
    route: '/settings',
    i18nKeyLabel: 'Settings',
    Icon: SettingsIcon,
  },
];

export const LOCALE_SWITCHER_ITEMS = [
  {
    locale: 'en',
    i18nKeyLabel: 'English',
  },
  {
    locale: 'de',
    i18nKeyLabel: 'German',
  },
];
