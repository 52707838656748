import { khaki } from '@config/mui/colors';

export default {
  styleOverrides: {
    root: {
      marginTop: 4,
      color: khaki[30],
      fontSize: 12,
      lineHeight: 1.3,
      letterSpacing: 0.4,
    },
  },
};
