import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { acceptUserVerificationRequest } from '@services/api/user/user.api';
import { UserVerificationInterface } from '@types-declaration/entities.types';

interface HookUseAcceptUserVerificationArgumentsInterface {
  ns: string;
  id: string;
  onSuccessSubmit?: (userVerification: UserVerificationInterface) => void;
}

interface HookUseAcceptUserVerificationReturnInterface {
  state: {
    pending: boolean;
  };
  handlers: {
    handleAcceptUserVerification: () => Promise<void>;
  };
}

const useAcceptUserVerification = (
  args: HookUseAcceptUserVerificationArgumentsInterface,
): HookUseAcceptUserVerificationReturnInterface => {
  const { ns, id, onSuccessSubmit } = args;

  const { t } = useTranslation(ns);
  const { enqueueSnackbar } = useSnackbar();

  const [pending, setPending] = useState<boolean>(false);

  const handleAcceptUserVerification = async (): Promise<void> => {
    try {
      setPending(true);

      const response = await acceptUserVerificationRequest({ params: { id } });
      enqueueSnackbar(t('You have successfully approved user verification'), {
        variant: 'success',
      });
      if (onSuccessSubmit != null) onSuccessSubmit(response);
    } catch (error) {
      const { message } = error as Error;

      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setPending(false);
    }
  };

  return {
    state: {
      pending,
    },
    handlers: {
      handleAcceptUserVerification,
    },
  };
};

export default useAcceptUserVerification;
