import { FC } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { useDataGridColumns } from '@pages/TenderDetails/hooks';
import { useAppSelector } from '@config/redux-toolkit';
import { selectTender } from '@services/store/tender';

const TenderOffers: FC = () => {
  const tender = useAppSelector(selectTender);

  const columns = useDataGridColumns();

  if (tender === null) return null;

  return (
    <DataGrid
      rows={tender.offers}
      rowCount={tender.offers.length}
      columns={columns}
      pageSizeOptions={[100]}
      disableRowSelectionOnClick
    />
  );
};

export default TenderOffers;
