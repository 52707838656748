import authAxiosInstance from '@config/axios/auth-instance';
import baseAxiosInstance from '@config/axios/base-instance';
import {
  GetAllCountryAllowlistResponseType,
  CreateManyCountryAllowlistResponseType,
  CreateManyCountryAllowlistArgumentsInterface,
} from './country-allowlist.types';

export const getAllCountryAllowlistRequest =
  async (): Promise<GetAllCountryAllowlistResponseType> => {
    return await baseAxiosInstance.get('/country-allowlist');
  };

export const createManyCountryAllowlistRequest = async (
  args: CreateManyCountryAllowlistArgumentsInterface,
): Promise<CreateManyCountryAllowlistResponseType> => {
  const { body } = args;

  return authAxiosInstance.post('/country-allowlist', body);
};
