import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Stack, Typography } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import Frame from '@components/Frame';

type Props = {
  value: number;
  label: string;
  from: Date;
  to?: Date;
};

const TotalCountCard: FC<Props> = (props) => {
  const { value, label, from, to = new Date() } = props;

  const { t } = useTranslation('home');

  return (
    <Frame flex={1}>
      <Typography variant="subtitle2" component="p">
        {label}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={2} mt={1}>
        <PeopleAltIcon color="primary" />
        <Typography variant="h6" component="p">
          {value}
        </Typography>
      </Stack>
      <Typography variant="body3" color="secondary" component="p" mt={2} mb={-1}>
        {t('from: date to: date', {
          from: format(from, 'dd MMM yyyy'),
          to: format(to, 'dd MMM yyyy'),
        })}
      </Typography>
    </Frame>
  );
};

export default TotalCountCard;
