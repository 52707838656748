import { FC, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

import { CONTENT_LANGUAGE_KEY } from '@constants/common.constants';
import { LOCALE_SWITCHER_ITEMS } from '@constants/navigation.constants';

type Props = {
  showLocale?: boolean;
};

const LocaleSwitcher: FC<Props> = (props) => {
  const { showLocale = false } = props;

  const { t, i18n } = useTranslation('common');

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (): void => setAnchorElement(null);

  const handleLanguageChange = (lang: string): void => {
    const currentLang = localStorage.getItem(CONTENT_LANGUAGE_KEY);

    if (lang !== currentLang) {
      localStorage.setItem(CONTENT_LANGUAGE_KEY, lang);
      i18n.changeLanguage(lang);
    }

    setAnchorElement(null);
  };

  return (
    <>
      <Button fullWidth onClick={handleOpen}>
        <LanguageIcon />
        {showLocale && (
          <Typography color="primary" component="span" ml={1}>
            {i18n.language}
          </Typography>
        )}
      </Button>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleClose}
      >
        {LOCALE_SWITCHER_ITEMS.map(({ locale, i18nKeyLabel }, index) => (
          <MenuItem key={index} onClick={() => handleLanguageChange(locale)}>
            <ListItemIcon>
              <LanguageIcon color="secondary" />
            </ListItemIcon>
            <Typography variant="body1" px={2}>
              {t(i18nKeyLabel)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LocaleSwitcher;
