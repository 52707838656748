import { FC, ReactNode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { store } from '@services/store';
import ThemeProvider from '@config/mui';

type Props = {
  children: ReactNode;
};

const Providers: FC<Props> = (props) => {
  const { children } = props;

  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <SnackbarProvider
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {children}
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
};

export default Providers;
