import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useDataGridColumns = (): GridColDef[] => {
  const { t } = useTranslation('user-verifications');
  const navigate = useNavigate();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: t('Created At'),
        width: 130,
        valueFormatter: (params) => format(new Date(params.value), 'dd MMM yyyy'),
      },
      {
        field: 'documentType',
        headerName: t('Document Type'),
        width: 170,
      },
      {
        field: 'country',
        headerName: t('Country'),
        flex: 2,
      },
      {
        field: 'documentNumber',
        headerName: t('Document Number'),
        flex: 2,
      },
      {
        field: 'user.name',
        headerName: t('Full Name'),
        flex: 3,
        valueGetter: (params) => params.row.user.name,
      },
      {
        field: 'user',
        headerName: t('Actions'),
        width: 90,
        sortable: false,
        renderCell: (params) => (
          <IconButton onClick={() => navigate(`/profiles/${params.value.id}`)}>
            <ArrowForwardIosIcon color="primary" />
          </IconButton>
        ),
      },
    ],
    [t, navigate],
  );

  return columns;
};

export default useDataGridColumns;
