export default {
  10: '#261A00',
  20: '#3F2E00',
  30: '#5B4300',
  40: '#785900',
  50: '#977100',
  60: '#B78A00',
  70: '#D8A300',
  80: '#FABD00',
  90: '#FFDF9E',
  95: '#FFEFD4',
  99: '#FFFBFF',
};
