import { FC } from 'react';
import { BoxProps } from '@mui/material';

import { Root } from './Frame.styles';

const Frame: FC<BoxProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Root p={2} borderRadius={2} {...rest}>
      {children}
    </Root>
  );
};

export default Frame;
