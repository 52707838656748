import axios from 'axios';

import { BACKEND_URL } from '@constants/common.constants';
import {
  setAuthorizationHeaderRequestInterceptor,
  setContentLanguageHeaderRequestInterceptor,
  transformResponseInterceptor,
  transformErrorInterceptor,
} from './interceptors';

const authAxiosInstance = axios.create({
  baseURL: `${BACKEND_URL}/api`,
  timeout: 5000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
});

authAxiosInstance.interceptors.request.use(setAuthorizationHeaderRequestInterceptor);
authAxiosInstance.interceptors.request.use(setContentLanguageHeaderRequestInterceptor);

authAxiosInstance.interceptors.response.use(
  transformResponseInterceptor,
  transformErrorInterceptor,
);

export default authAxiosInstance;
