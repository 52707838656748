import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { Tabs } from './components';
import SearchFieldWithDebounce from '@components/SearchFieldWithDebounce';
import { useSnackbarErrorMessage } from '@hooks';
import { useDataGridColumns, useTenders } from '@pages/Tenders/hooks';
import { useAppSelector } from '@config/redux-toolkit';
import { selectTenders, selectTendersError, selectTendersPending } from '@services/store/tenders';
import { GET_ALL_TENDERS_PAGE_SIZE_OPTIONS } from '@constants/common.constants';

const TendersSection: FC = () => {
  const { t } = useTranslation('tenders');

  const tenders = useAppSelector(selectTenders);
  const pending = useAppSelector(selectTendersPending);
  const error = useAppSelector(selectTendersError);

  useSnackbarErrorMessage(error?.message);

  const columns = useDataGridColumns();

  const {
    state: { queryParams },
    handlers: {
      handleTabChange,
      handleSearchChange,
      handleSortModelChange,
      handlePaginationModelChange,
    },
  } = useTenders();

  return (
    <Box component="section" mx={4} mb={4}>
      {queryParams.tab && <Tabs value={queryParams.tab} onChange={handleTabChange} />}
      <SearchFieldWithDebounce
        onChange={handleSearchChange}
        placeholder={t("Search by creator's first and last name")}
        fullWidth
        sx={{ mb: 2 }}
      />
      <DataGrid
        rows={tenders.data}
        rowCount={tenders.totalRecords}
        columns={columns}
        loading={pending}
        sortingMode="server"
        paginationMode="server"
        disableRowSelectionOnClick
        pageSizeOptions={GET_ALL_TENDERS_PAGE_SIZE_OPTIONS}
        onSortModelChange={handleSortModelChange}
        onPaginationModelChange={handlePaginationModelChange}
        paginationModel={{ pageSize: Number(queryParams.take), page: Number(queryParams.page) - 1 }}
        slotProps={{
          pagination: {
            labelRowsPerPage: t('Rows per page:'),
            labelDisplayedRows: ({ from, to, count }) => `${from}–${to} / ${count}`,
          },
        }}
      />
    </Box>
  );
};

export default TendersSection;
