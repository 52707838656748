import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './users.state';
import { getAllUsersThunk } from './users.actions';

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllUsersThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getAllUsersThunk.fulfilled, (state, action) => {
        state.pending = false;
        state.users = action.payload;
      })
      .addCase(getAllUsersThunk.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || null;
      });
  },
});

export default usersSlice.reducer;
