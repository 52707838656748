import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCategoryByIdRequest, updateCategoryRequest } from '@services/api/category/category.api';
import {
  GetCategoryByIdArgumentsInterface,
  GetCategoryByIdResponseType,
  UpdateCategoryArgumentsInterface,
  UpdateCategoryResponseType,
} from '@services/api/category/category.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const getCategoryByIdThunk = createAsyncThunk<
  GetCategoryByIdResponseType,
  GetCategoryByIdArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('category/getCategoryByIdThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await getCategoryByIdRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});

export const updateCategoryThunk = createAsyncThunk<
  UpdateCategoryResponseType,
  UpdateCategoryArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('category/updateCategoryThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await updateCategoryRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});
