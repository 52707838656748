import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import Frame from '@components/Frame';
import { selectCategory } from '@services/store/category';
import { useAppSelector } from '@config/redux-toolkit';
import { ImageWrapper } from './CategoryCard.styles';

const CategoryCard: FC = () => {
  const { t } = useTranslation('category-details');

  const category = useAppSelector(selectCategory);

  if (category === null) return null;

  const { name, image } = category;

  return (
    <Frame>
      <Typography variant="subtitle2" component="p" color="secondary" mb={0.5}>
        {t('Category name')}
      </Typography>
      <Typography variant="h6" component="h2" mb={2}>
        {name}
      </Typography>
      {image !== null ? (
        <>
          <Typography variant="subtitle2" component="p" color="secondary" mb={1}>
            {t('Category image')}
          </Typography>
          <ImageWrapper>
            <img src={image.fileURL} alt={name} />
          </ImageWrapper>
        </>
      ) : (
        <Stack spacing={1} direction="row" alignItems="center">
          <WarningIcon color="warning" />
          <Typography variant="body1">{t('This category has no image!')}</Typography>
        </Stack>
      )}
    </Frame>
  );
};

export default CategoryCard;
