import { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';

import { TenderGeneral, TenderOffers } from './components';
import { useSnackbarErrorMessage } from '@hooks';
import { useAppSelector } from '@config/redux-toolkit';
import { selectTenderError } from '@services/store/tender';
import { TenderDetailsPageTabs } from '@enums/common.enums';

const TenderDetailsSection: FC = () => {
  const { t } = useTranslation('tender-details');

  const error = useAppSelector(selectTenderError);

  useSnackbarErrorMessage(error?.message);

  const [tab, setTab] = useState<TenderDetailsPageTabs>(TenderDetailsPageTabs.GENERAL);

  const handleTabChange = (_: SyntheticEvent, tab: TenderDetailsPageTabs): void => setTab(tab);

  return (
    <Box component="section" mb={4} mx={4}>
      <Tabs value={tab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab value={TenderDetailsPageTabs.GENERAL} label={t('General')} />
        <Tab value={TenderDetailsPageTabs.OFFERS} label={t('Offers')} />
      </Tabs>
      {tab === TenderDetailsPageTabs.GENERAL && <TenderGeneral />}
      {tab === TenderDetailsPageTabs.OFFERS && <TenderOffers />}
    </Box>
  );
};

export default TenderDetailsSection;
