import { FC, ReactNode } from 'react';
import { ThemeProvider as MaterialThemeProvider, CssBaseline } from '@mui/material';
import { ThemeProvider as StylesThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import { palette, breakpoints, typography, shadows } from '@config/mui/config';
import components from '@config/mui/overrides';

const theme = createTheme({ palette, breakpoints, typography, components });
theme.shadows.unshift(...shadows);

interface Props {
  children: ReactNode;
}

const ThemeProvider: FC<Props> = (props) => {
  const { children } = props;

  return (
    <MaterialThemeProvider theme={theme}>
      <StylesThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </StylesThemeProvider>
    </MaterialThemeProvider>
  );
};

export default ThemeProvider;
