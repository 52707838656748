export default {
  10: '#00210E',
  20: '#00391C',
  30: '#00522B',
  40: '#006D3A',
  50: '#00894B',
  60: '#1BA55E',
  70: '#42C177',
  80: '#62DE90',
  90: '#7FFBAA',
  95: '#C3FFD0',
  99: '#FCFCFC',
};
