import { ChangeEvent, FC, useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

import { debounce } from '@utils/common.utils';
import { SEARCH_FIELD_DEBOUNCE_IN_MS } from '@constants/common.constants';

const debounceValueChange = debounce(
  (callback, value: string) => callback(value),
  SEARCH_FIELD_DEBOUNCE_IN_MS,
);

type Props = {
  onChange: (value: string) => void;
} & Omit<TextFieldProps, 'onChange'>;

const SearchFieldWithDebounce: FC<Props> = (props) => {
  const { onChange, ...rest } = props;

  const [value, setValue] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => setValue(event.target.value);

  useEffect(() => {
    debounceValueChange(onChange, value);
  }, [value, onChange]);

  return <TextField {...rest} value={value} onChange={handleChange} />;
};

export default SearchFieldWithDebounce;
