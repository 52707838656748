import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { UserVerificationInterface } from '@types-declaration/entities.types';
import { getUserVerificationByIdRequest } from '@services/api/user/user.api';

interface HookUseUserVerificationReturnInterface {
  userVerification: UserVerificationInterface | null;
  pending: boolean;
}

const useUserVerification = (id: string): HookUseUserVerificationReturnInterface => {
  const { enqueueSnackbar } = useSnackbar();

  const [userVerification, setUserVerification] = useState<UserVerificationInterface | null>(null);
  const [pending, setPending] = useState<boolean>(false);

  const getUserVerificationById = useCallback(async () => {
    try {
      setPending(true);

      const response = await getUserVerificationByIdRequest({ params: { id } });
      setUserVerification(response);
    } catch (error) {
      const { message } = error as Error;

      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setPending(false);
    }
  }, [id, enqueueSnackbar]);

  useEffect(() => {
    void getUserVerificationById();
  }, [getUserVerificationById]);

  return {
    userVerification,
    pending,
  };
};

export default useUserVerification;
