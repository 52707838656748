import { styled, Avatar as MuiAvatar, Chip as MuiChip } from '@mui/material';

export const Avatar = styled(MuiAvatar)({
  alignSelf: 'center',
  width: 240,
  height: 240,
  borderRadius: '50%',
});

export const Chip = styled(MuiChip)({
  alignSelf: 'flex-start',
  fontSize: 16,
  padding: 8,
});
