import { FC } from 'react';
import { Box, Container, TextField } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PasswordField from '@components/PasswordField';
import { useAppDispatch, useAppSelector } from '@config/redux-toolkit';
import { useFieldErrors } from '@hooks';
import { selectUserError, selectUserPending, signInThunk } from '@services/store/auth';
import { SignInBodyInterface } from '@services/api/auth/auth.types';
import { LoadingButton, Typography } from './SignInForm.styles';

const SignInForm: FC = () => {
  const { t } = useTranslation('sign-in');
  const dispatch = useAppDispatch();
  const pending = useAppSelector(selectUserPending);
  const error = useAppSelector(selectUserError);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignInBodyInterface>();

  useFieldErrors(error?.errors, setError);

  const submit: SubmitHandler<SignInBodyInterface> = (fieldValues) => {
    dispatch(signInThunk({ body: fieldValues }));
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 16, mb: 4 }}>
      <Typography variant="h4" component="h1" sx={{ typography: { xs: 'h6', sm: 'h4' } }}>
        {t('Sign-in as administrator')}
      </Typography>
      <Box component="form" onSubmit={handleSubmit(submit)} display="flex" flexDirection="column">
        <TextField
          placeholder={t('Email')}
          fullWidth
          autoComplete="email"
          sx={{ mb: 3 }}
          {...register('email')}
          {...(Boolean(errors?.email) && {
            error: Boolean(errors?.email),
            helperText: errors?.email?.message,
          })}
        />
        <PasswordField
          type="password"
          placeholder={t('Password')}
          fullWidth
          autoComplete="password"
          {...register('password')}
          {...(Boolean(errors?.password) && {
            error: Boolean(errors?.password),
            helperText: errors?.password?.message,
          })}
        />
        <LoadingButton loading={pending} type="submit" variant="contained">
          {t('Login')}
        </LoadingButton>
      </Box>
    </Container>
  );
};

export default SignInForm;
