import authAxiosInstance from '@config/axios/auth-instance';
import {
  DestroyFileArgumentsType,
  DestroyFileResponseType,
  UploadMultipleFilesArgumentsType,
  UploadMultipleFilesResponseType,
  UploadSingleFileArgumentsType,
  UploadSingleFileResponseType,
} from './file.types';

export const uploadSingleFileRequest = async (
  args: UploadSingleFileArgumentsType,
): Promise<UploadSingleFileResponseType> => {
  const { body } = args;

  return await authAxiosInstance.post('/files/upload/single', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const uploadMultipleFilesRequest = async (
  args: UploadMultipleFilesArgumentsType,
): Promise<UploadMultipleFilesResponseType> => {
  const { body } = args;

  return await authAxiosInstance.post('/files/upload/multiple', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const destroyFileRequest = async (
  args: DestroyFileArgumentsType,
): Promise<DestroyFileResponseType> => {
  const { body } = args;

  return await authAxiosInstance.post(`/files/destroy`, body);
};
