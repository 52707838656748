import { CategoriesStateInterface } from './categories.types';

export const initialState: CategoriesStateInterface = {
  categories: {
    data: [],
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
  pending: false,
  error: null,
};
