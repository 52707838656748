import { FC, ReactNode } from 'react';
import { TypographyProps } from '@mui/material';

import { ReactComponent as StatusNew } from '@assets/icons/status-new.svg';
import { ReactComponent as StatusAccepted } from '@assets/icons/status-accepted.svg';
import { ReactComponent as StatusCompleted } from '@assets/icons/status-completed.svg';
import { ReactComponent as StatusCanceled } from '@assets/icons/status-canceled.svg';
import { TenderStatus as TenderStatusEnum } from '@enums/common.enums';
import { Typography } from './TenderStatus.styles';

type Props = {
  status: TenderStatusEnum;
} & TypographyProps;

const getStatusIcon = (status: TenderStatusEnum): ReactNode => {
  return {
    [TenderStatusEnum.NEW]: <StatusNew />,
    [TenderStatusEnum.ACCEPTED]: <StatusAccepted />,
    [TenderStatusEnum.COMPLETED]: <StatusCompleted />,
    [TenderStatusEnum.CANCELED]: <StatusCanceled />,
  }[status];
};

const TenderStatus: FC<Props> = (props) => {
  const { status, ...rest } = props;

  return (
    <Typography variant="caption1" {...rest}>
      {getStatusIcon(status)}
      {status}
    </Typography>
  );
};

export default TenderStatus;
