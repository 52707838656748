import { createAsyncThunk } from '@reduxjs/toolkit';

import { getTenderWithRelationsByIdRequest } from '@services/api/tender/tender.api';
import {
  GetTenderWithRelationsByIdArgumentsInterface,
  GetTenderWithRelationsByIdResponseType,
} from '@services/api/tender/tender.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const getTenderWithRelationsByIdThunk = createAsyncThunk<
  GetTenderWithRelationsByIdResponseType,
  GetTenderWithRelationsByIdArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('tender/getTenderWithRelationsByIdThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await getTenderWithRelationsByIdRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});
