import { green, khaki, red } from '@config/mui/colors';

export default {
  styleOverrides: {
    root: {
      color: green[10],
      lineHeight: 1.5,
      letterSpacing: 0.5,
      borderColor: khaki[50],

      'label + &': {
        marginTop: 22,
      },

      '& input': {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        fontSize: 16,
      },

      '& input::placeholder': {
        color: khaki[30],
        opacity: 1,
      },

      '& textarea::placeholder': {
        color: khaki[30],
        opacity: 1,
      },

      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: green[10],
        },
      },

      '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: red[40],
        },
      },
    },
    notchedOutline: {
      top: 0,

      legend: {
        display: 'none',
      },
    },
  },
};
