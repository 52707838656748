import { styled, Typography as MuiTypography } from '@mui/material';

import { khaki } from '@config/mui/colors';

export const Typography = styled(MuiTypography)({
  display: 'flex',
  alignItems: 'center',
  padding: '6px 16px 6px 8px',
  border: `1px solid ${khaki[80]}`,
  borderRadius: 32,

  '& svg': {
    marginRight: 8,
  },
}) as typeof MuiTypography;
