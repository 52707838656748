import { FC, useCallback, useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useSwipeDirection } from '@hooks';
import { SwipeDirection } from '@enums/common.enums';
import { FileInterface } from '@types-declaration/entities.types';
import { CloseButton, ImageWrapper, SkipButton, Root } from './MediaViewer.styles';

type Props = {
  files: FileInterface[];
  activeIndex: number;
  onClose: () => void;
  onNext: () => void;
  onPrev: () => void;
};

const MediaViewer: FC<Props> = (props) => {
  const { files, activeIndex, onClose, onNext, onPrev } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const swipeDirection = useSwipeDirection({ containerRef });

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose();
      if (event.key === 'ArrowRight') onNext();
      if (event.key === 'ArrowLeft') onPrev();
    },
    [onClose, onPrev, onNext],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (swipeDirection === SwipeDirection.LEFT) onNext();
    if (swipeDirection === SwipeDirection.RIGHT) onPrev();
  }, [swipeDirection, onNext, onPrev]);

  const { fileURL, width, height, filename } = files[activeIndex];

  return (
    <Root>
      <SkipButton disableTouchRipple onClick={onPrev} sx={{ left: 0 }}>
        <ChevronLeftIcon />
      </SkipButton>
      <ImageWrapper ref={containerRef}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <img src={fileURL} width={width} height={height} alt={filename} />
      </ImageWrapper>
      <SkipButton disableTouchRipple onClick={onNext} sx={{ right: 0 }}>
        <ChevronRightIcon />
      </SkipButton>
    </Root>
  );
};

export default MediaViewer;
