import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileRejection } from 'react-dropzone';
import { Control, FieldArrayWithId, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { useCloudinary } from '@hooks';
import { useAppDispatch, useAppSelector } from '@config/redux-toolkit';
import { CloudinaryFolder } from '@enums/common.enums';
import { dropzoneErrorsParser } from '@utils/cloudinary.utils';
import { locales } from '@config/i18n';
import { FileInterface } from '@types-declaration/entities.types';
import { updateCategoryThunk } from '@services/store/category/category.actions';
import { selectCategory } from '@services/store/category';
import { UpdateCategoryTranslationBodyInterface } from '@services/api/category/category.types';

interface UpdateCategoryFieldValuesInterface {
  translations: UpdateCategoryTranslationBodyInterface[];
}

interface HookUseUpdateCategoryArgumentsInterface {
  onSuccessSubmit: () => void;
}

interface HookUseUpdateCategoryReturnInterface {
  state: {
    fields: FieldArrayWithId<UpdateCategoryFieldValuesInterface, 'translations', 'id'>[];
    control: Control<UpdateCategoryFieldValuesInterface, any>;
    file: FileInterface | null;
    uploadPending: boolean;
    destroyPending: boolean;
    submitPending: boolean;
    error: string | null;
  };
  handlers: {
    handleAcceptedFile: (files: File[]) => Promise<void>;
    handleRejectedFile: (files: FileRejection[]) => void;
    handleDestroyFile: () => Promise<void>;
    handleSubmit: (event: FormEvent) => Promise<void>;
  };
}

const useUpdateCategory = (
  args: HookUseUpdateCategoryArgumentsInterface,
): HookUseUpdateCategoryReturnInterface => {
  const { onSuccessSubmit } = args;

  const { t } = useTranslation('category-details');
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const category = useAppSelector(selectCategory);

  const {
    state: { uploadedFiles, uploadPending, destroyPending },
    actions: { uploadSingleFile },
  } = useCloudinary();

  const [file, setFile] = useState<FileInterface | null>(null);
  const [submitPending, setSubmitPending] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { control, handleSubmit } = useForm<UpdateCategoryFieldValuesInterface>();
  const { fields, append, remove } = useFieldArray({ control, name: 'translations' });

  const handleAcceptedFile = async (files: File[]): Promise<void> => {
    const [file] = files;

    await uploadSingleFile(file, CloudinaryFolder.CATEGORIES, { crop: 'thumb' });
    setError(null);
  };

  const handleRejectedFile = (files: FileRejection[]): void => {
    const errors = dropzoneErrorsParser(files);
    setError(errors.join(', '));
  };

  const handleDestroyFile = async (): Promise<void> => {
    setFile(null);
    setError(null);
  };

  const submit: SubmitHandler<UpdateCategoryFieldValuesInterface> = async ({ translations }) => {
    if (category !== null) {
      if (file === null) {
        enqueueSnackbar({ message: t('Upload an image for category, please'), variant: 'error' });
        return;
      }

      setSubmitPending(true);

      await dispatch(
        updateCategoryThunk({ params: { id: category.id }, body: { translations, file } }),
      )
        .then(() => {
          onSuccessSubmit();
          setError(null);
          enqueueSnackbar({
            message: t('You have successfully updated category'),
            variant: 'success',
          });
        })
        .finally(() => {
          setSubmitPending(false);
        });
    }
  };

  useEffect(() => {
    if (uploadedFiles !== null) setFile(uploadedFiles as FileInterface);
  }, [uploadedFiles]);

  useEffect(() => {
    if (category !== null) {
      setFile(category.image);

      locales.forEach((locale) => {
        const translation = category.translations.find(
          (translation) => translation.locale === locale,
        );

        append({ locale, name: translation?.name || '' });
      });
    }

    return () => {
      remove();
    };
  }, [category, append, remove]);

  return {
    state: {
      fields,
      control,
      file,
      uploadPending,
      destroyPending,
      submitPending,
      error,
    },
    handlers: {
      handleAcceptedFile,
      handleRejectedFile,
      handleDestroyFile,
      handleSubmit: handleSubmit(submit),
    },
  };
};

export default useUpdateCategory;
