import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import {
  ProfileEducations,
  ProfileExperiences,
  ProfileLanguages,
  ProfileServices,
} from './components';
import { useAppSelector } from '@config/redux-toolkit';
import { selectProfile } from '@services/store/profile';

const ProfileAdditional: FC = () => {
  const { t } = useTranslation('profile-details');

  const profile = useAppSelector(selectProfile);

  if (profile === null) return null;

  const { name, educations, experiences, languages, subcategories } = profile;

  if (
    educations.length === 0 &&
    experiences.length === 0 &&
    languages.length === 0 &&
    subcategories.length === 0
  ) {
    return (
      <Typography variant="h6" component="h1" mt={8}>
        {t("There is no additional information in user's profile", {
          user: name,
        })}
      </Typography>
    );
  }

  return (
    <Stack spacing={3}>
      <ProfileEducations educations={educations} />
      <ProfileExperiences experiences={experiences} />
      <ProfileLanguages languages={languages} />
      <ProfileServices services={subcategories} />
    </Stack>
  );
};

export default ProfileAdditional;
