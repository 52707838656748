import { AppStoreType } from '@config/redux-toolkit';
import { GetCategoryByIdResponseType } from '@services/api/category/category.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const selectCategory = (state: AppStoreType): GetCategoryByIdResponseType | null => {
  return state.categoryReducer.category;
};

export const selectCategoryPending = (state: AppStoreType): boolean => {
  return state.categoryReducer.pending;
};

export const selectCategoryError = (state: AppStoreType): ResponseFailureInterface | null => {
  return state.categoryReducer.error;
};
