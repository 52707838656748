import { SetStateAction } from 'react';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';

import {
  QUERY_PARAM_PAGE_DEFAULT_VALUE,
  QUERY_PARAM_SORT_BY_DEFAULT_VALUE,
} from '@constants/common.constants';
import { SortDirection } from '@enums/common.enums';
import { QueryParamsInterface } from '@types-declaration/common.types';

interface HookUseDataGridHandlersArgumentsInterface {
  queryParams: QueryParamsInterface;
  setQueryParams: (value: SetStateAction<QueryParamsInterface>) => void;
}

interface HookUseDataGridHandlersReturnInterface {
  handleSortModelChange: (sortModel: GridSortModel) => void;
  handlePaginationModelChange: (paginationModel: GridPaginationModel) => void;
}

const useDataGridHandlers = (
  args: HookUseDataGridHandlersArgumentsInterface,
): HookUseDataGridHandlersReturnInterface => {
  const { queryParams, setQueryParams } = args;

  const handleSortModelChange = (sortModel: GridSortModel): void => {
    let sortBy = QUERY_PARAM_SORT_BY_DEFAULT_VALUE;
    let sortDirection = SortDirection.ASC;

    if (sortModel.length > 0) {
      sortBy = sortModel[0].field;
      sortDirection = sortModel[0].sort === 'asc' ? SortDirection.ASC : SortDirection.DESC;
    }

    setQueryParams((previousState) => ({
      ...previousState,
      page: QUERY_PARAM_PAGE_DEFAULT_VALUE,
      sortBy,
      sortDirection,
    }));
  };

  const handlePaginationModelChange = (paginationModel: GridPaginationModel): void => {
    const { page, pageSize } = paginationModel;

    if (pageSize === Number(queryParams.take)) {
      setQueryParams((previousState) => ({ ...previousState, page: String(page + 1) }));
    } else {
      setQueryParams((previousState) => ({
        ...previousState,
        take: String(pageSize),
        page: QUERY_PARAM_PAGE_DEFAULT_VALUE,
      }));
    }
  };

  return {
    handleSortModelChange,
    handlePaginationModelChange,
  };
};

export default useDataGridHandlers;
