import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Pagination } from '@mui/material';

import { CategoryCards, DialogCreateCategory } from './components';
import SearchFieldWithDebounce from '@components/SearchFieldWithDebounce';
import { useManageDialog } from '@hooks';
import { useCategories } from '@pages/Categories/hooks';
import { useAppSelector } from '@config/redux-toolkit';
import { selectCategories } from '@services/store/categories';

const CategoriesSection: FC = () => {
  const { t } = useTranslation('categories');

  const categories = useAppSelector(selectCategories);

  const {
    state: { paginationCount, queryParams },
    handlers: { handleChangePage, handleSearchChange },
  } = useCategories();

  const {
    state: { open },
    handlers: { handleOpen, handleClose },
  } = useManageDialog();

  return (
    <Box component="section" mx={4} mb={4}>
      <SearchFieldWithDebounce
        onChange={handleSearchChange}
        placeholder={t('Search by category name')}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button variant="contained" onClick={handleOpen} sx={{ mb: 4 }}>
        {t('Create a category')}
      </Button>
      <DialogCreateCategory open={open} onClose={handleClose} />
      <CategoryCards categories={categories} />
      {paginationCount > 1 && (
        <Pagination
          count={paginationCount}
          page={Number(queryParams.page)}
          siblingCount={1}
          sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}
          onChange={handleChangePage}
        />
      )}
    </Box>
  );
};

export default CategoriesSection;
