export default {
  10: '#161D17',
  20: '#2B322C',
  30: '#414941',
  40: '#586059',
  50: '#717971',
  60: '#8B938A',
  70: '#A5ADA4',
  80: '#C1C9BF',
  90: '#DDE5DB',
  95: '#EBF3E9',
  99: '#F4FCF2',
};
