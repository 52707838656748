import { useEffect, useRef, useState } from 'react';

import { SwipeDirection } from '@enums/common.enums';

interface HookUseSwipeDirectionArgumentsInterface {
  containerRef: React.RefObject<HTMLElement>;
  swipeThreshold?: number;
}

const useSwipeDirection = (args: HookUseSwipeDirectionArgumentsInterface): SwipeDirection => {
  const { containerRef, swipeThreshold = 50 } = args;

  const startX = useRef<number | null>(null);
  const startY = useRef<number | null>(null);
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirection>(SwipeDirection.NONE);

  useEffect(() => {
    const handleTouchStart = (event: TouchEvent): void => {
      startX.current = event.touches[0].clientX;
      startY.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event: TouchEvent): void => {
      if (containerRef.current === null || startX.current === null || startY.current === null) {
        return;
      }

      const deltaX = event.touches[0].clientX - startX.current;
      const deltaY = event.touches[0].clientY - startY.current;

      if (Math.abs(deltaX) >= swipeThreshold || Math.abs(deltaY) >= swipeThreshold) {
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          setSwipeDirection(deltaX > 0 ? SwipeDirection.RIGHT : SwipeDirection.LEFT);
        } else {
          setSwipeDirection(deltaY > 0 ? SwipeDirection.DOWN : SwipeDirection.UP);
        }

        startX.current = null;
        startY.current = null;
      }
    };

    const handleTouchEnd = (): void => {
      setSwipeDirection(SwipeDirection.NONE);
      startX.current = null;
      startY.current = null;
    };

    const rootRef = containerRef.current;

    if (rootRef !== null) {
      rootRef.addEventListener('touchstart', handleTouchStart, { passive: true });
      rootRef.addEventListener('touchmove', handleTouchMove, { passive: true });
      rootRef.addEventListener('touchend', handleTouchEnd, { passive: true });
    }

    return () => {
      if (rootRef !== null) {
        rootRef.removeEventListener('touchstart', handleTouchStart);
        rootRef.removeEventListener('touchmove', handleTouchMove);
        rootRef.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [swipeThreshold, containerRef]);

  return swipeDirection;
};

export default useSwipeDirection;
