import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileRejection } from 'react-dropzone';
import { Control, FieldArrayWithId, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { useCloudinary } from '@hooks';
import { useAppDispatch } from '@config/redux-toolkit';
import { CloudinaryFolder } from '@enums/common.enums';
import { dropzoneErrorsParser } from '@utils/cloudinary.utils';
import { createCategoryThunk } from '@services/store/categories';
import { locales } from '@config/i18n';
import { FileInterface } from '@types-declaration/entities.types';
import { CreateCategoryTranslationBodyInterface } from '@services/api/category/category.types';

interface CreateCategoryFieldValuesInterface {
  translations: CreateCategoryTranslationBodyInterface[];
}

interface HookUseCreateCategoryArgumentsInterface {
  onSuccessSubmit: () => void;
}

interface HookUseCreateCategoryReturnInterface {
  state: {
    fields: FieldArrayWithId<CreateCategoryFieldValuesInterface, 'translations', 'id'>[];
    control: Control<CreateCategoryFieldValuesInterface, any>;
    file: FileInterface | null;
    uploadPending: boolean;
    destroyPending: boolean;
    submitPending: boolean;
    error: string | null;
  };
  handlers: {
    handleAcceptedFile: (files: File[]) => Promise<void>;
    handleRejectedFile: (files: FileRejection[]) => void;
    handleDestroyFile: (publicId: string) => Promise<void>;
    handleSubmit: (event: FormEvent) => Promise<void>;
  };
}

const useCreateCategory = (
  args: HookUseCreateCategoryArgumentsInterface,
): HookUseCreateCategoryReturnInterface => {
  const { onSuccessSubmit } = args;

  const { t } = useTranslation('categories');
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const {
    state: { uploadedFiles, uploadPending, destroyPending },
    actions: { uploadSingleFile, destroyFile },
  } = useCloudinary();

  const [file, setFile] = useState<FileInterface | null>(null);
  const [submitPending, setSubmitPending] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { control, handleSubmit } = useForm<CreateCategoryFieldValuesInterface>();
  const { fields, append, remove } = useFieldArray({ control, name: 'translations' });

  const handleAcceptedFile = async (files: File[]): Promise<void> => {
    const [file] = files;

    await uploadSingleFile(file, CloudinaryFolder.CATEGORIES, { crop: 'thumb' });
    setError(null);
  };

  const handleRejectedFile = (files: FileRejection[]): void => {
    const errors = dropzoneErrorsParser(files);
    setError(errors.join(', '));
  };

  const handleDestroyFile = async (publicId: string): Promise<void> => {
    await destroyFile(publicId);
    setFile(null);
    setError(null);
  };

  const submit: SubmitHandler<CreateCategoryFieldValuesInterface> = async ({ translations }) => {
    if (file === null) {
      enqueueSnackbar({ message: t('Upload an image for category, please'), variant: 'error' });
      return;
    }

    setSubmitPending(true);

    await dispatch(createCategoryThunk({ body: { translations, file } }))
      .then(() => {
        onSuccessSubmit();
        setFile(null);
        setError(null);
        enqueueSnackbar({
          message: t('You have successfully created new category'),
          variant: 'success',
        });
        remove();
        locales.forEach((locale) => append({ locale, name: '' }));
      })
      .finally(() => {
        setSubmitPending(false);
      });
  };

  useEffect(() => {
    if (uploadedFiles !== null) setFile(uploadedFiles as FileInterface);
  }, [uploadedFiles]);

  useEffect(() => {
    locales.forEach((locale) => append({ locale, name: '' }));
  }, [append]);

  return {
    state: {
      fields,
      control,
      file,
      uploadPending,
      destroyPending,
      submitPending,
      error,
    },
    handlers: {
      handleAcceptedFile,
      handleRejectedFile,
      handleDestroyFile,
      handleSubmit: handleSubmit(submit),
    },
  };
};

export default useCreateCategory;
