import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, ListItemText, ListItem, ListItemIcon } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { format } from 'date-fns';

import { UserExperienceInterface } from '@types-declaration/entities.types';

type Props = {
  experiences: UserExperienceInterface[];
};

const ProfileExperiences: FC<Props> = (props) => {
  const { experiences } = props;

  const { t } = useTranslation('profile-details');

  if (experiences.length === 0) return null;

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle3" color="text.secondary">
        {t('Experiences')}
      </Typography>
      <Stack spacing={1} mt={2}>
        {experiences.map(({ id, title, company, start, end }) => (
          <ListItem disablePadding key={id}>
            <ListItemIcon>
              <CheckCircleOutlineOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={[title, company].filter(Boolean).join(' - ')}
              secondary={[
                format(new Date(start), 'dd MMM yyyy'),
                end !== null ? format(new Date(end), 'dd MMM yyyy') : t('Present'),
              ].join(' - ')}
            />
          </ListItem>
        ))}
      </Stack>
    </Stack>
  );
};

export default ProfileExperiences;
