import {
  styled,
  Drawer as MuiDrawer,
  Box as MuiBox,
  ButtonGroup as MuiButtonGroup,
  Button as MuiButton,
} from '@mui/material';

import { HIDDEN_NAVIGATION_WIDTH, NAVIGATION_WIDTH } from '@constants/navigation.constants';

type DrawerProps = {
  open: boolean;
};

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({ open }) => ({
  '& .MuiPaper-root': {
    width: HIDDEN_NAVIGATION_WIDTH,
    overflowX: 'hidden',
    transition: 'width 0.2s ease',

    ...(open && {
      width: NAVIGATION_WIDTH,
    }),
  },
}));

export const Box = styled(MuiBox)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 16,
});

export const ButtonGroup = styled(MuiButtonGroup)({
  marginTop: 32,
  marginBottom: 'auto',
});

export const Button = styled(MuiButton)({
  justifyContent: 'flex-start',

  '& svg': {
    marginRight: 8,
  },
}) as typeof MuiButton;
