import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './tender.state';
import { getTenderWithRelationsByIdThunk } from './tender.actions';

export const tenderSlice = createSlice({
  name: 'tender',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTenderWithRelationsByIdThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getTenderWithRelationsByIdThunk.fulfilled, (state, action) => {
        state.pending = false;
        state.tender = action.payload;
      })
      .addCase(getTenderWithRelationsByIdThunk.rejected, (state, action) => {
        state.pending = false;
        state.tender = null;
        state.error = action.payload || null;
      });
  },
});

export default tenderSlice.reducer;
