import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TendersSection } from './components';
import HeaderSection from '@components/HeaderSection';

const Tenders: FC = () => {
  const { t } = useTranslation('tenders');

  return (
    <>
      <HeaderSection primary={t('All Tenders')} my={2} mx={4} />
      <TendersSection />
    </>
  );
};

export default Tenders;
