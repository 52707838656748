import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UserVerificationsSection } from './components';
import HeaderSection from '@components/HeaderSection';

const UserVerifications: FC = () => {
  const { t } = useTranslation('user-verifications');

  return (
    <>
      <HeaderSection primary={t('All User Verifications')} my={2} mx={4} />
      <UserVerificationsSection />
    </>
  );
};

export default UserVerifications;
