import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './categories.state';
import { createCategoryThunk, getAllCategoriesThunk } from './categories.actions';

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllCategoriesThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getAllCategoriesThunk.fulfilled, (state, action) => {
        state.pending = false;
        state.categories = action.payload;
      })
      .addCase(getAllCategoriesThunk.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || null;
      })
      .addCase(createCategoryThunk.fulfilled, (state, action) => {
        action.payload.totalSubcategories = 0;
        action.payload.name = action.payload.translations[0].name;
        state.categories.data = [action.payload, ...state.categories.data];
      })
      .addCase(createCategoryThunk.rejected, (state, action) => {
        state.error = action.payload || null;
      });
  },
});

export default categoriesSlice.reducer;
