import { styled, Box, CircularProgress } from '@mui/material';

import { khaki } from '@config/mui/colors';

export const Dropzone = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  border: `1px solid ${khaki[80]}`,
  padding: 8,
  borderRadius: 4,
  cursor: 'pointer',
});

export const LoadingIndicator = styled(CircularProgress)({
  position: 'absolute',
  left: 'calc(50% - 20px)',
  top: 'calc(50% - 30px)',
  zIndex: 100,
});
