import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import LoadingButton from '@components/LoadingButton';
import { FileInterface } from '@types-declaration/entities.types';
import { ImageWrapper } from './PreviewPhotoContentAndActions.styles';

type Props = {
  file: FileInterface;
  destroyPending: boolean;
  onDestroyFile: (publicId: string) => void;
};

const PreviewPhotoContentAndActions: FC<Props> = (props) => {
  const { file, destroyPending, onDestroyFile } = props;

  const { t } = useTranslation('common');

  return (
    <Box>
      <ImageWrapper>
        <img src={file.fileURL} width={file.width} height={file.height} alt={t('Uploaded photo')} />
      </ImageWrapper>
      <LoadingButton
        loading={destroyPending}
        variant="outlined"
        color="error"
        sx={{ mb: 1 }}
        onClick={() => onDestroyFile(file.publicId)}
      >
        {t('Remove Image')}
      </LoadingButton>
    </Box>
  );
};

export default PreviewPhotoContentAndActions;
