import { khaki } from '@config/mui/colors';

export default {
  styleOverrides: {
    wrapper: {
      fontSize: 11,
      lineHeight: 1.45,
      color: khaki[40],
    },
  },
};
