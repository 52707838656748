import { Button as MuiButton, Avatar as MuiAvatar, styled } from '@mui/material';

export const Button = styled(MuiButton)({
  display: 'flex',
  alignItems: 'center',
});

export const Avatar = styled(MuiAvatar)({
  width: 28,
  height: 28,
});
