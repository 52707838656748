import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ProfileDetailsSection } from './components';
import HeaderSection from '@components/HeaderSection';
import { getProfileByIdThunk } from '@services/store/profile';
import { useAppDispatch } from '@config/redux-toolkit';

const ProfileDetails: FC = () => {
  const { t } = useTranslation('profile-details');
  const { userId } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (typeof userId === 'string') {
      dispatch(getProfileByIdThunk({ params: { id: userId } }));
    }
  }, [userId, dispatch]);

  return (
    <>
      <HeaderSection primary={t('Profile Details')} my={2} mx={4} />
      <ProfileDetailsSection />
    </>
  );
};

export default ProfileDetails;
