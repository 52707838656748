import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { GridColDef } from '@mui/x-data-grid';
import { Chip, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import TenderStatus from '@components/TenderStatus';
import { timeRangeFormatter } from '@utils/common.utils';

const useDataGridColumns = (): GridColDef[] => {
  const { t } = useTranslation('tenders');
  const navigate = useNavigate();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: t('Created At'),
        width: 120,
        valueFormatter: (params) => format(new Date(params.value), 'dd MMM yyyy'),
      },
      {
        field: 'category',
        headerName: t('Category'),
        width: 160,
        sortable: false,
        renderCell: (params) => <Chip label={params.value.name} variant="outlined" />,
      },
      {
        field: 'subcategory',
        headerName: t('Subcategory'),
        width: 180,
        sortable: false,
        renderCell: (params) => <Chip label={params.value.name} variant="outlined" />,
      },
      {
        field: 'status',
        headerName: t('Status'),
        width: 160,
        sortable: false,
        renderCell: (params) => (
          <TenderStatus status={params.value} sx={{ width: 'fit-content' }} />
        ),
      },
      {
        field: 'from',
        headerName: t('Date From'),
        width: 120,
        valueFormatter: (params) =>
          params.value ? format(new Date(params.value), 'dd MMM yyyy') : t('Any date'),
      },
      {
        field: 'to',
        headerName: t('Date To'),
        width: 120,
        valueFormatter: (params) =>
          params.value ? format(new Date(params.value), 'dd MMM yyyy') : t('Any date'),
      },
      {
        field: 'time',
        headerName: t('Time'),
        width: 110,
        sortable: false,
        valueFormatter: (params) =>
          params.value?.length > 0 ? timeRangeFormatter(params.value).join(', ') : t('Any time'),
      },
      {
        field: 'title',
        headerName: t('Title'),
        minWidth: 160,
        flex: 4,
      },
      {
        field: 'client',
        headerName: t('Creator Full Name'),
        minWidth: 160,
        flex: 1,
        valueFormatter: (params) => params.value.name,
      },
      {
        field: 'id',
        headerName: t('Actions'),
        width: 80,
        sortable: false,
        renderCell: (params) => (
          <IconButton onClick={() => navigate(`/tenders/${params.value}`)}>
            <ArrowForwardIosIcon color="primary" />
          </IconButton>
        ),
      },
    ],
    [t, navigate],
  );

  return columns;
};

export default useDataGridColumns;
