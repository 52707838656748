import { Theme } from '@mui/material';

import { green, grey, khaki } from '@config/mui/colors';

export default {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      width: 52,
      height: 32,
      marginRight: 12,
      padding: 0,

      '&:hover': {
        '.MuiSwitch-thumb': {
          color: khaki[30],
        },
      },

      '&:hover .Mui-checked': {
        '.MuiSwitch-thumb': {
          color: green[90],
        },
      },
    },
    thumb: ({ theme }: { theme: Theme }) => ({
      boxSizing: 'border-box' as const,
      width: 24,
      height: 24,
      color: khaki[50],
      boxShadow: 'none',
      transition: theme.transitions.create('all', { duration: 300 }),
    }),
    track: {
      backgroundColor: grey[90],
      border: '2px solid',
      borderColor: khaki[50],
      borderRadius: 100,
      opacity: 1,
    },
    switchBase: ({ theme }: { theme: Theme }) => ({
      marginTop: 4,
      marginLeft: 5,
      padding: 0,
      transitionDuration: '300ms',
      transform: 'scale(0.7)',

      '& .MuiSwitch-input': {
        top: '-50%',
        width: '400%',
        height: '200%',
      },

      '&.Mui-checked': {
        transform: 'translateX(18px) scale(1)',

        '.MuiSwitch-thumb': {
          color: theme.palette.common.white,
        },

        '& + .MuiSwitch-track': {
          backgroundColor: green[40],
          borderColor: green[40],
          opacity: 1,
        },
      },

      '&.Mui-disabled .MuiSwitch-thumb': {
        color: green[10],
        opacity: 0.4,
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.2,
      },
    }),
  },
};
