import { AppStoreType } from '@config/redux-toolkit';
import { GetUserReviewsResponseType } from '@services/api/user/user.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const selectUserReviews = (state: AppStoreType): GetUserReviewsResponseType => {
  return state.userReviewsReducer.userReviews;
};

export const selectUserReviewsPending = (state: AppStoreType): boolean => {
  return state.userReviewsReducer.pending;
};

export const selectUserReviewsError = (state: AppStoreType): ResponseFailureInterface | null => {
  return state.userReviewsReducer.error;
};
