import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useAppDispatch } from '@config/redux-toolkit';
import { useAppSelector } from '@config/redux-toolkit';
import { refreshTokenThunk, selectUserError } from '@services/store/auth';
import { REFRESH_TOKEN_KEY } from '@constants/common.constants';

type Props = {
  children: ReactNode;
};

const Auth: FC<Props> = (props) => {
  const { children } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectUserError);

  const [pending, setPending] = useState<boolean>(true);

  const refreshToken = useCallback(async () => {
    const token = localStorage.getItem(REFRESH_TOKEN_KEY);

    if (token !== null) {
      setPending(true);

      await dispatch(refreshTokenThunk({ body: { token } }));
    }

    setPending(false);
  }, [dispatch]);

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  useEffect(() => {
    if (error !== null && error.message !== null) {
      enqueueSnackbar({ message: error.message, variant: 'error', preventDuplicate: true });
    }
  }, [error, enqueueSnackbar]);

  if (pending) return <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} />;

  return <>{children}</>;
};

export default Auth;
