import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { rejectUserVerificationRequest } from '@services/api/user/user.api';
import { RejectUserVerificationArgumentsInterface } from '@services/api/user/user.types';
import { UserVerificationInterface } from '@types-declaration/entities.types';

interface HookUseRejectUserVerificationArgumentsInterface {
  ns: string;
  onSuccessSubmit?: (userVerification: UserVerificationInterface) => void;
}

interface HookUseRejectUserVerificationReturnInterface {
  state: {
    pending: boolean;
  };
  functions: {
    rejectUserVerification: (args: RejectUserVerificationArgumentsInterface) => Promise<void>;
  };
}

const useRejectUserVerification = (
  args: HookUseRejectUserVerificationArgumentsInterface,
): HookUseRejectUserVerificationReturnInterface => {
  const { ns, onSuccessSubmit } = args;

  const { t } = useTranslation(ns);
  const { enqueueSnackbar } = useSnackbar();

  const [pending, setPending] = useState<boolean>(false);

  const rejectUserVerification = async (
    args: RejectUserVerificationArgumentsInterface,
  ): Promise<void> => {
    try {
      setPending(true);

      const response = await rejectUserVerificationRequest(args);
      enqueueSnackbar(t('You have rejected user verification'), { variant: 'success' });
      if (onSuccessSubmit != null) onSuccessSubmit(response);
    } catch (error) {
      const { message } = error as Error;

      if (message != null) enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setPending(false);
    }
  };

  return {
    state: {
      pending,
    },
    functions: {
      rejectUserVerification,
    },
  };
};

export default useRejectUserVerification;
