import { createAsyncThunk } from '@reduxjs/toolkit';

import { logoutRequest, refreshTokenRequest, signInRequest } from '@services/api/auth/auth.api';
import {
  LogoutArgumentsInterface,
  LogoutResponseType,
  RefreshTokenArgumentsInterface,
  RefreshTokenResponseType,
  SignInArgumentsInterface,
  SignInResponseType,
} from '@services/api/auth/auth.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const signInThunk = createAsyncThunk<
  SignInResponseType,
  SignInArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('auth/signInThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await signInRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});

export const refreshTokenThunk = createAsyncThunk<
  RefreshTokenResponseType,
  RefreshTokenArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('auth/refreshTokenThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await refreshTokenRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});

export const logoutThunk = createAsyncThunk<
  LogoutResponseType,
  LogoutArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('auth/logoutThunk', async (args, { rejectWithValue }) => {
  try {
    await logoutRequest(args);
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});
