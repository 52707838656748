import { FC, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, ListItemIcon, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import { logoutThunk } from '@services/store/auth';
import { useAppDispatch, useAppSelector } from '@config/redux-toolkit';
import { REFRESH_TOKEN_KEY } from '@constants/common.constants';
import { Button, Avatar } from './ProfileMenu.styles';

type Props = {
  showFullName?: boolean;
};

const ProfileMenu: FC<Props> = (props) => {
  const { showFullName = false } = props;

  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.authReducer);

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (): void => setAnchorElement(null);

  const handleLogout = async (): Promise<void> => {
    const token = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (token !== null) await dispatch(logoutThunk({ body: { token } }));
    navigate('/sign-in');
  };

  if (user === null) return null;

  return (
    <>
      <Button onClick={handleOpen}>
        <Avatar {...(user.photo !== null && { src: user.photo.thumbnailURL })} alt={user.name} />
        {showFullName && (
          <Typography noWrap variant="body1" color="secondary" ml={1}>
            {user.name}
          </Typography>
        )}
      </Button>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleClose}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <Typography variant="body1">{t('Logout')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
