import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, FieldArrayWithId, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { locales } from '@config/i18n';
import { updateSubcategoryThunk } from '@services/store/subcategories';
import { selectSubcategory } from '@services/store/subcategory';
import { useAppDispatch, useAppSelector } from '@config/redux-toolkit';
import { UpdateSubcategoryTranslationBodyInterface } from '@services/api/subcategory/subcategory.types';

interface UpdateSubcategoryFieldValuesInterface {
  translations: UpdateSubcategoryTranslationBodyInterface[];
}

interface HookUseUpdateSubcategoryArgumentsInterface {
  onSuccessSubmit: () => void;
}

interface HookUseUpdateSubcategoryReturnInterface {
  state: {
    fields: FieldArrayWithId<UpdateSubcategoryFieldValuesInterface, 'translations', 'id'>[];
    control: Control<UpdateSubcategoryFieldValuesInterface, any>;
    pending: boolean;
  };
  handlers: {
    handleSubmit: (event: FormEvent) => void;
  };
}

const useUpdateSubcategory = (
  args: HookUseUpdateSubcategoryArgumentsInterface,
): HookUseUpdateSubcategoryReturnInterface => {
  const { onSuccessSubmit } = args;

  const { t } = useTranslation('category-details');
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const subcategory = useAppSelector(selectSubcategory);

  const { control, handleSubmit, reset } = useForm<UpdateSubcategoryFieldValuesInterface>();
  const { fields, append, remove } = useFieldArray({ control, name: 'translations' });

  const [pending, setPending] = useState<boolean>(false);

  const submit: SubmitHandler<UpdateSubcategoryFieldValuesInterface> = ({ translations }) => {
    if (subcategory !== null) {
      setPending(true);
      dispatch(updateSubcategoryThunk({ params: { id: subcategory.id }, body: { translations } }))
        .then(() => {
          onSuccessSubmit();
          enqueueSnackbar({
            message: t('You have successfully updated subcategory'),
            variant: 'success',
          });
          reset();
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  useEffect(() => {
    if (subcategory !== null) {
      locales.forEach((locale) => {
        const translation = subcategory.translations.find(
          (translation) => translation.locale === locale,
        );

        append({ locale, name: translation?.name || '' });
      });
    }

    return () => {
      remove();
    };
  }, [subcategory, append, remove]);

  return {
    state: {
      fields,
      control,
      pending,
    },
    handlers: {
      handleSubmit: handleSubmit(submit),
    },
  };
};

export default useUpdateSubcategory;
