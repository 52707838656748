import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './auth.state';
import { logoutThunk, refreshTokenThunk, signInThunk } from './auth.actions';

export const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(signInThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(signInThunk.fulfilled, (state, action) => {
        state.pending = false;
        state.error = null;
        state.user = action.payload.user;
      })
      .addCase(signInThunk.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || null;
        state.user = null;
      })
      .addCase(refreshTokenThunk.fulfilled, (state, action) => {
        state.error = null;
        state.user = action.payload.user;
      })
      .addCase(refreshTokenThunk.rejected, (state, action) => {
        state.error = action.payload || null;
        state.user = null;
      })
      .addCase(logoutThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(logoutThunk.fulfilled, (state) => {
        state.pending = false;
        state.error = null;
        state.user = null;
      })
      .addCase(logoutThunk.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || null;
        state.user = null;
      });
  },
});

export default userSlice.reducer;
