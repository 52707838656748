import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import MediaGallery from '@components/MediaGallery';
import { FileInterface } from '@types-declaration/entities.types';

type Props = {
  ns: string;
  files: FileInterface[];
};

const ViewDocuments: FC<Props> = (props) => {
  const { ns, files } = props;

  const { t } = useTranslation(ns);

  return (
    <Stack spacing={1}>
      <Typography>{t('View document:')}</Typography>
      <MediaGallery files={files} />
    </Stack>
  );
};

export default ViewDocuments;
