import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import { GetAllCategoriesResponseType } from '@services/api/category/category.types';
import { Frame, ImageWrapper } from './CategoryCards.styles';

type Props = {
  categories: GetAllCategoriesResponseType;
};

const CategoryCards: FC<Props> = (props) => {
  const { categories } = props;

  const { t } = useTranslation('categories');

  if (categories.totalFilteredRecords === 0) return null;

  return (
    <Stack spacing={2}>
      {categories.data.map((category) => {
        const { id, image, name, totalSubcategories } = category;

        return (
          <Frame key={id}>
            {image !== null ? (
              <ImageWrapper>
                <img src={image.fileURL} alt={name} />
              </ImageWrapper>
            ) : (
              <Skeleton variant="rounded" width={400} height={200} />
            )}
            <Stack flex={2} spacing={1} ml={4} mr={2}>
              <Typography variant="h6">{name}</Typography>
              {totalSubcategories > 0 ? (
                <Typography variant="body1">
                  {t('Subcategories count: ')}
                  {totalSubcategories}
                </Typography>
              ) : (
                <Stack spacing={1} direction="row" alignItems="center">
                  <WarningIcon color="warning" />
                  <Typography variant="body1">
                    {t('This category has no subcategories!')}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" component={Link} to={`/categories/${id}`}>
                {t('Category Details')}
              </Button>
            </Box>
          </Frame>
        );
      })}
    </Stack>
  );
};

export default CategoryCards;
