import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './user-reviews.state';
import { getUserReviewsThunk } from './user-reviews.actions';

export const userReviewsSlice = createSlice({
  name: 'user-reviews',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserReviewsThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getUserReviewsThunk.fulfilled, (state, action) => {
        state.pending = false;
        state.userReviews = action.payload;
      })
      .addCase(getUserReviewsThunk.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || null;
      });
  },
});

export default userReviewsSlice.reducer;
