import { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs as MuiTabs, Tab } from '@mui/material';

import { UserRole } from '@enums/common.enums';
import { QUERY_PARAM_TAB_DEFAULT_VALUE } from '@constants/common.constants';

type Props = {
  value: string;
  onChange: (_: SyntheticEvent<Element, Event>, value: string) => void;
};

const Tabs: FC<Props> = (props) => {
  const { value, onChange } = props;

  const { t } = useTranslation('profiles');

  return (
    <MuiTabs
      value={value}
      onChange={onChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={{ mb: 2 }}
    >
      <Tab value={QUERY_PARAM_TAB_DEFAULT_VALUE} label={t('All')} />
      <Tab value={UserRole.CLIENT} label={t('Clients')} />
      <Tab value={UserRole.PRO} label={t('Bees')} />
    </MuiTabs>
  );
};

export default Tabs;
