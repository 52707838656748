import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  pending?: boolean;
  failure?: boolean;
  empty?: boolean;
  PendingComponent?: ReactNode;
  FailureComponent?: ReactNode;
  EmptyComponent?: ReactNode;
};

const Observer: FC<Props> = (props) => {
  const {
    children,
    pending = false,
    failure = false,
    empty = false,
    PendingComponent,
    FailureComponent,
    EmptyComponent,
  } = props;

  if (pending) return <>{PendingComponent}</>;
  if (failure) return <>{FailureComponent}</>;
  if (empty) return <>{EmptyComponent}</>;

  return <>{children}</>;
};

export default Observer;
