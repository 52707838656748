import { ChangeEvent, useCallback, useEffect } from 'react';

import { useQueryParams } from '@hooks';
import { getAllCategoriesThunk, selectCategories } from '@services/store/categories';
import { useAppDispatch, useAppSelector } from '@config/redux-toolkit';
import { SortDirection } from '@enums/common.enums';
import {
  QUERY_PARAM_PAGE_DEFAULT_VALUE,
  QUERY_PARAM_SEARCH_DEFAULT_VALUE,
  QUERY_PARAM_TAKE_DEFAULT_VALUE,
} from '@constants/common.constants';
import { QueryParamsInterface } from '@types-declaration/common.types';

interface HookUseCategoriesReturnInterface {
  state: {
    paginationCount: number;
    queryParams: QueryParamsInterface;
  };
  handlers: {
    handleChangePage: (_: ChangeEvent<unknown>, page: number) => void;
    handleSearchChange: (search: string) => void;
  };
}

const useCategories = (): HookUseCategoriesReturnInterface => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(selectCategories);

  const {
    state: { queryParams },
    handlers: { getQuery, setQueryParams },
  } = useQueryParams({
    defaultQueryParams: {
      sortDirectionValue: SortDirection.DESC,
      takeValue: QUERY_PARAM_TAKE_DEFAULT_VALUE,
      searchValue: QUERY_PARAM_SEARCH_DEFAULT_VALUE,
    },
  });

  const handleChangePage = (_: ChangeEvent<unknown>, page: number): void => {
    setQueryParams((previousState) => ({ ...previousState, page: String(page) }));
  };

  const handleSearchChange = useCallback(
    (search: string): void => {
      setQueryParams((previousState) => ({
        ...previousState,
        page: QUERY_PARAM_PAGE_DEFAULT_VALUE,
        search,
      }));
    },
    [setQueryParams],
  );

  useEffect(() => {
    const query = getQuery();
    dispatch(getAllCategoriesThunk({ query }));
  }, [getQuery, dispatch]);

  const paginationCount = Math.ceil(
    categories.totalRecords / Number(QUERY_PARAM_TAKE_DEFAULT_VALUE),
  );

  return {
    state: {
      paginationCount,
      queryParams,
    },
    handlers: {
      handleChangePage,
      handleSearchChange,
    },
  };
};

export default useCategories;
