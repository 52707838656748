import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DashboardSection, TopRatedProsSection } from './components';
import HeaderSection from '@components/HeaderSection';

const Home: FC = () => {
  const { t } = useTranslation('home');

  return (
    <>
      <HeaderSection primary={t('BusyBees statistics')} my={2} mx={4} />
      <DashboardSection />
      <TopRatedProsSection />
    </>
  );
};

export default Home;
