import { UserVerificationsStateInterface } from './user-verifications.types';

export const initialState: UserVerificationsStateInterface = {
  userVerifications: {
    data: [],
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
  pending: false,
  error: null,
};
