import { styled, Avatar as MuiAvatar, Rating as MuiRating } from '@mui/material';

export const Avatar = styled(MuiAvatar)({
  width: 112,
  height: 112,
  marginRight: 'auto',
  marginBottom: 16,
  marginLeft: 'auto',
  borderRadius: '50%',
});

export const Rating = styled(MuiRating)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 8,
});
