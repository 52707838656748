import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './subcategory.state';
import { SubcategoryInterface } from '@types-declaration/entities.types';

export const subcategorySlice = createSlice({
  name: 'subcategory',
  initialState,
  reducers: {
    setSubcategoryAction: (state, action: PayloadAction<SubcategoryInterface>) => {
      state.subcategory = action.payload;
    },
  },
});

export const { setSubcategoryAction } = subcategorySlice.actions;

export default subcategorySlice.reducer;
