import { useEffect } from 'react';
import { UseFormSetError } from 'react-hook-form';

import { FormFieldsErrorsType } from '@types-declaration/common.types';

const useFieldErrors = (
  errors: FormFieldsErrorsType | null | undefined,
  setError: UseFormSetError<any>,
): void => {
  useEffect(() => {
    if (errors !== null && errors !== undefined) {
      Object.entries(errors).forEach(([key, value]) => {
        setError(key as any, { type: 'manual', message: value[0] });
      });
    }
  }, [errors, setError]);
};

export default useFieldErrors;
