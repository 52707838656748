import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Stack, Typography } from '@mui/material';

import { UserLanguageInterface } from '@types-declaration/entities.types';
import { LANGUAGES } from '@constants/common.constants';

type Props = {
  languages: UserLanguageInterface[];
};

const ProfileLanguages: FC<Props> = (props) => {
  const { languages } = props;

  const { t } = useTranslation('profile-details');

  if (languages.length === 0) return null;

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle3" color="text.secondary">
        {t('Languages')}
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {languages.map(({ id, ISOCode }) => (
          <Chip key={id} variant="outlined" label={LANGUAGES[ISOCode].name} />
        ))}
      </Stack>
    </Stack>
  );
};

export default ProfileLanguages;
