import MuiButton from './MuiButton';
import MuiCheckbox from './MuiCheckbox';
import MuiCollapse from './MuiCollapse';
import MuiDialog from './MuiDialog';
import MuiDialogActions from './MuiDialogActions';
import MuiDialogContent from './MuiDialogContent';
import MuiDialogTitle from './MuiDialogTitle';
import MuiDivider from './MuiDivider';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormHelperText from './MuiFormHelperText';
import MuiInputAdornment from './MuiInputAdornment';
import MuiInputLabel from './MuiInputLabel';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPagination from './MuiPagination';
import MuiRadio from './MuiRadio';
import MuiSwitch from './MuiSwitch';

export default {
  MuiButton,
  MuiCheckbox,
  MuiCollapse,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiDivider,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiInputAdornment,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiPagination,
  MuiRadio,
  MuiSwitch,
};
