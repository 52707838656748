import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAllUsersRequest } from '@services/api/user/user.api';
import {
  GetAllUsersArgumentsInterface,
  GetAllUsersResponseType,
} from '@services/api/user/user.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const getAllUsersThunk = createAsyncThunk<
  GetAllUsersResponseType,
  GetAllUsersArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('users/getAllUsersThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await getAllUsersRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});
