import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import {
  createManyCountryAllowlistRequest,
  getAllCountryAllowlistRequest,
} from '@services/api/country-allowlist/country-allowlist.api';
import {
  CountryAllowlistDataInterface,
  CreateManyCountryAllowlistBodyInterface,
} from '@services/api/country-allowlist/country-allowlist.types';
import { RestCountryInterface } from '@services/rest-countries/v3.1/rest-countries.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';
import { CountryAllowlistInterface } from '@types-declaration/entities.types';

interface HookUseCountryAllowlistInterface {
  state: {
    countryAllowlist: CountryAllowlistInterface[];
    pending: boolean;
  };
  functions: {
    createCountryAllowlist: (body: CreateManyCountryAllowlistBodyInterface) => Promise<void>;
    mapRestCountriesToCountryAllowlistData: (
      countries: RestCountryInterface[],
    ) => CountryAllowlistDataInterface[];
    mapCountryAllowlistToISOCodesArray: (countryAllowlist: CountryAllowlistInterface[]) => string[];
  };
}

const useCountryAllowlist = (): HookUseCountryAllowlistInterface => {
  const { enqueueSnackbar } = useSnackbar();

  const [countryAllowlist, setCountryAllowlist] = useState<CountryAllowlistInterface[]>([]);
  const [pending, setPending] = useState<boolean>(false);

  const createCountryAllowlist = useCallback(
    async (body: CreateManyCountryAllowlistBodyInterface) => {
      try {
        setPending(true);

        const response = await createManyCountryAllowlistRequest({ body });
        setCountryAllowlist(response);
      } catch (error) {
        const { message } = error as ResponseFailureInterface;

        if (message !== null) enqueueSnackbar(message, { variant: 'error' });
      } finally {
        setPending(false);
      }
    },
    [setCountryAllowlist, enqueueSnackbar],
  );

  const mapRestCountriesToCountryAllowlistData = (
    countries: RestCountryInterface[],
  ): CountryAllowlistDataInterface[] => {
    return countries.map((country) => {
      const { cca2, idd, name } = country;
      const { root, suffixes } = idd;

      let ISDCode = root.replace(/^\+/, '');
      if (suffixes.length === 1) ISDCode += suffixes[0];

      return { name: name.common, ISOCode: cca2, ISDCode };
    });
  };

  const mapCountryAllowlistToISOCodesArray = useCallback(
    (countryAllowlist: CountryAllowlistInterface[]): string[] => {
      return countryAllowlist.map(({ ISOCode }) => ISOCode);
    },
    [],
  );

  const getCountryAllowlist = useCallback(async () => {
    try {
      setPending(true);

      const response = await getAllCountryAllowlistRequest();
      setCountryAllowlist(response);
    } catch (error) {
      const { message } = error as ResponseFailureInterface;

      if (message !== null) enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setPending(false);
    }
  }, [setCountryAllowlist, enqueueSnackbar]);

  useEffect(() => {
    getCountryAllowlist();
  }, [getCountryAllowlist]);

  return {
    state: {
      countryAllowlist,
      pending,
    },
    functions: {
      createCountryAllowlist,
      mapRestCountriesToCountryAllowlistData,
      mapCountryAllowlistToISOCodesArray,
    },
  };
};

export default useCountryAllowlist;
