export default {
  10: '#001F26',
  20: '#02363F',
  30: '#214C57',
  40: '#3A646F',
  50: '#537D88',
  60: '#6D97A3',
  70: '#87B2BE',
  80: '#A2CEDA',
  90: '#BEEAF6',
  95: '#D7F6FF',
  99: '#F8FDFF',
};
