import { createAsyncThunk } from '@reduxjs/toolkit';

import { getUnverifiedUserVerificationsRequest } from '@services/api/user/user.api';
import {
  GetUnverifiedUserVerificationsArgumentsInterface,
  GetUnverifiedUserVerificationsResponseType,
} from '@services/api/user/user.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const getUnverifiedUserVerificationsThunk = createAsyncThunk<
  GetUnverifiedUserVerificationsResponseType,
  GetUnverifiedUserVerificationsArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>(
  'users/getUnverifiedUserVerificationsThunk',
  async (args, { fulfillWithValue, rejectWithValue }) => {
    try {
      return fulfillWithValue(await getUnverifiedUserVerificationsRequest(args));
    } catch (error) {
      return rejectWithValue(error as ResponseFailureInterface);
    }
  },
);
