import { useEffect } from 'react';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';

import { useDataGridHandlers, useQueryParams } from '@hooks';
import { getUnverifiedUserVerificationsThunk } from '@services/store/user-verifications';
import { useAppDispatch } from '@config/redux-toolkit';
import { QueryParamsInterface } from '@types-declaration/common.types';

interface HookUseUnverifiedUserVerificationsReturnInterface {
  state: {
    queryParams: QueryParamsInterface;
  };
  handlers: {
    handleSortModelChange: (sortModel: GridSortModel) => void;
    handlePaginationModelChange: (paginationModel: GridPaginationModel) => void;
  };
}

const useUnverifiedUserVerifications = (): HookUseUnverifiedUserVerificationsReturnInterface => {
  const dispatch = useAppDispatch();

  const {
    state: { queryParams },
    handlers: { setQueryParams, getQuery },
  } = useQueryParams({ defaultQueryParams: {} });

  const { handleSortModelChange, handlePaginationModelChange } = useDataGridHandlers({
    queryParams,
    setQueryParams,
  });

  useEffect(() => {
    const query = getQuery();
    dispatch(getUnverifiedUserVerificationsThunk({ query }));
  }, [dispatch, getQuery]);

  return {
    state: {
      queryParams,
    },
    handlers: {
      handleSortModelChange,
      handlePaginationModelChange,
    },
  };
};

export default useUnverifiedUserVerifications;
