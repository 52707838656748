import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Stack, Typography } from '@mui/material';

import { SubcategoryInterface } from '@types-declaration/entities.types';

type Props = {
  services: SubcategoryInterface[];
};

const ProfileServices: FC<Props> = (props) => {
  const { services } = props;

  const { t } = useTranslation('profile-details');

  if (services.length === 0) return null;

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle3" color="text.secondary">
        {t('Services')}
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {services.map((service) => (
          <Chip key={service.id} variant="outlined" label={service.name} />
        ))}
      </Stack>
    </Stack>
  );
};

export default ProfileServices;
