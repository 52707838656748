import baseAxiosInstance from '@config/axios/base-instance';
import {
  LogoutArgumentsInterface,
  LogoutResponseType,
  RefreshTokenArgumentsInterface,
  RefreshTokenResponseType,
  SignInArgumentsInterface,
  SignInResponseType,
} from './auth.types';

export const signInRequest = async (
  args: SignInArgumentsInterface,
): Promise<SignInResponseType> => {
  const { body } = args;
  return await baseAxiosInstance.post('/auth/sign-in/admin', body);
};

export const refreshTokenRequest = async (
  args: RefreshTokenArgumentsInterface,
): Promise<RefreshTokenResponseType> => {
  const { body } = args;
  return await baseAxiosInstance.post('/auth/refresh-token/admin', body);
};

export const logoutRequest = async (
  args: LogoutArgumentsInterface,
): Promise<LogoutResponseType> => {
  const { body } = args;
  return await baseAxiosInstance.post('/auth/logout', body);
};
