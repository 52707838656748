import { useState } from 'react';
import { useSnackbar } from 'notistack';

import {
  destroyFileRequest,
  uploadMultipleFilesRequest,
  uploadSingleFileRequest,
} from '@services/api/file/file.api';
import { setTransformationOptionsToFormData } from '@utils/cloudinary.utils';
import { FileInterface } from '@types-declaration/entities.types';
import { CommonTransformationOptions } from '@types-declaration/cloudinary.types';

interface HookUseCloudinaryReturnInterface {
  state: {
    uploadPending: boolean;
    destroyPending: boolean;
    uploadedFiles: FileInterface | FileInterface[] | null;
  };
  actions: {
    uploadSingleFile: (
      file: File,
      folder: string,
      options?: CommonTransformationOptions,
    ) => Promise<void>;
    uploadMultipleFiles: (
      files: File[],
      folder: string,
      options?: CommonTransformationOptions,
    ) => Promise<void>;
    destroyFile: (publicId: string) => Promise<any>;
  };
}

const useCloudinary = (): HookUseCloudinaryReturnInterface => {
  const { enqueueSnackbar } = useSnackbar();

  const [uploadPending, setUploadPending] = useState<boolean>(false);
  const [destroyPending, setDestroyPending] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<FileInterface | FileInterface[] | null>(null);

  const uploadSingleFile = async (
    file: File,
    folder: string,
    options?: CommonTransformationOptions,
  ): Promise<void> => {
    const formData = new FormData();

    try {
      setUploadPending(true);

      if (options !== undefined) setTransformationOptionsToFormData(formData, options);

      formData.append('rootFolder', folder);
      formData.append('file', file);

      setUploadedFiles(await uploadSingleFileRequest({ body: formData }));
    } catch (error) {
      const { message } = error as Error;

      if (message !== '') {
        enqueueSnackbar(message, { variant: 'error', preventDuplicate: true });
      }
    } finally {
      setUploadPending(false);
    }
  };

  const uploadMultipleFiles = async (
    files: File[],
    folder: string,
    options?: CommonTransformationOptions,
  ): Promise<void> => {
    const formData = new FormData();

    try {
      setUploadPending(true);

      if (options !== undefined) setTransformationOptionsToFormData(formData, options);

      formData.append('rootFolder', folder);
      files.forEach((file) => formData.append('files', file));

      setUploadedFiles(await uploadMultipleFilesRequest({ body: formData }));
    } catch (error) {
      const { message } = error as Error;

      if (message !== '') {
        enqueueSnackbar(message, { variant: 'error', preventDuplicate: true });
      }
    } finally {
      setUploadPending(false);
    }
  };

  const destroyFile = async (publicId: string): Promise<any> => {
    try {
      setDestroyPending(true);

      await destroyFileRequest({ body: { publicId } });
    } catch (error) {
      const { message } = error as Error;

      if (message !== '') {
        enqueueSnackbar(message, { variant: 'error', preventDuplicate: true });
      }
    } finally {
      setDestroyPending(false);
    }
  };

  return {
    state: { uploadPending, destroyPending, uploadedFiles },
    actions: { uploadSingleFile, uploadMultipleFiles, destroyFile },
  };
};

export default useCloudinary;
