import { green } from '@config/mui/colors';

export default {
  styleOverrides: {
    colorPrimary: {
      color: green[10],

      '&.Mui-checked': {
        color: green[10],
      },
    },
  },
};
