import authAxiosInstance from '@config/axios/auth-instance';
import {
  AcceptUserVerificationArgumentsInterface,
  AcceptUserVerificationResponseType,
  GetAllUsersArgumentsInterface,
  GetAllUsersResponseType,
  GetProfileByIdArgumentsInterface,
  GetProfileByIdResponseType,
  GetUserReviewsArgumentsInterface,
  GetUserReviewsResponseType,
  GetUserStatisticsArgumentsInterface,
  GetUserStatisticsResponseType,
  GetUnverifiedUserVerificationsArgumentsInterface,
  GetUnverifiedUserVerificationsResponseType,
  GetUserVerificationByIdArgumentsInterface,
  GetUserVerificationByIdResponseType,
  RejectUserVerificationArgumentsInterface,
  RejectUserVerificationResponseType,
} from './user.types';

export const getAllUsersRequest = async (
  args: GetAllUsersArgumentsInterface,
): Promise<GetAllUsersResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get('/users', { params: query });
};

export const getProfileByIdRequest = async (
  args: GetProfileByIdArgumentsInterface,
): Promise<GetProfileByIdResponseType> => {
  const { params } = args;

  return await authAxiosInstance.get(`/users/${params.id}/profile`);
};

export const getUserReviewsRequest = async (
  args: GetUserReviewsArgumentsInterface,
): Promise<GetUserReviewsResponseType> => {
  const { params, query } = args;

  return await authAxiosInstance.get(`users/${params.id}/reviews`, { params: query });
};

export const getUserStatisticsRequest = async (
  args: GetUserStatisticsArgumentsInterface,
): Promise<GetUserStatisticsResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get('/users/statistics', { params: query });
};

export const getUnverifiedUserVerificationsRequest = async (
  args: GetUnverifiedUserVerificationsArgumentsInterface,
): Promise<GetUnverifiedUserVerificationsResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get('/users/verification/unverified', { params: query });
};

export const getUserVerificationByIdRequest = async (
  args: GetUserVerificationByIdArgumentsInterface,
): Promise<GetUserVerificationByIdResponseType> => {
  const { params } = args;

  return await authAxiosInstance.get(`/users/verification/${params.id}`);
};

export const acceptUserVerificationRequest = async (
  args: AcceptUserVerificationArgumentsInterface,
): Promise<AcceptUserVerificationResponseType> => {
  const { params } = args;

  return await authAxiosInstance.patch(`users/verification/${params.id}/accept`);
};

export const rejectUserVerificationRequest = async (
  args: RejectUserVerificationArgumentsInterface,
): Promise<RejectUserVerificationResponseType> => {
  const { params, body } = args;

  return await authAxiosInstance.patch(`users/verification/${params.id}/reject`, body);
};
