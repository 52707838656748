import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { format } from 'date-fns';

import { UserVerificationInterface } from '@types-declaration/entities.types';

type Props = {
  userVerification: UserVerificationInterface;
  ns: string;
};

const UserVerificationDetails: FC<Props> = (props) => {
  const { userVerification, ns } = props;

  const { t } = useTranslation(ns);

  const { createdAt, updatedAt, country, documentType, documentNumber } = userVerification;

  const USER_VERIFICATION_DETAILS = useMemo(
    () => [
      {
        i18nKey: 'Created at:',
        value: format(new Date(createdAt), 'dd MMMM yyyy'),
      },
      {
        i18nKey: 'Updated at:',
        value: format(new Date(updatedAt), 'dd MMMM yyyy'),
      },
      {
        i18nKey: 'Country',
        value: country,
      },
      {
        i18nKey: 'Document type:',
        value: documentType,
      },
      {
        i18nKey: 'Document number:',
        value: documentNumber,
      },
    ],
    [createdAt, updatedAt, country, documentType, documentNumber],
  );

  return (
    <List>
      {USER_VERIFICATION_DETAILS.map(({ i18nKey, value }, index) => (
        <ListItem key={index}>
          <ListItemText>
            <Typography variant="subtitle2" component="span" color="primary" mr={1}>
              {t(i18nKey)}
            </Typography>
            {value}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default UserVerificationDetails;
