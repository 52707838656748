import { FC, ReactNode } from 'react';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

type Props = {
  primary: ReactNode;
  primaryTypographyProps?: TypographyProps;
} & BoxProps;

const HeaderSection: FC<Props> = (props) => {
  const { primary, primaryTypographyProps, ...rest } = props;

  return (
    <Box component="header" {...rest}>
      <Typography variant="h4" component="h1" {...primaryTypographyProps}>
        {primary}
      </Typography>
    </Box>
  );
};

export default HeaderSection;
