import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import useCountries from './useCountries';
import useCountryAllowlist from './useCountryAllowlist';
import { RestCountryInterface } from '@services/rest-countries/v3.1/rest-countries.types';

interface HookUseCountryAllowlistAutocompleteInterface {
  state: {
    value: RestCountryInterface[];
    options: RestCountryInterface[];
    disabled: boolean;
    pending: boolean;
  };
  handlers: {
    handleChange: (event: SyntheticEvent, value: RestCountryInterface[]) => void;
    handleSelectAll: () => void;
    handleSubmit: () => Promise<void>;
  };
}

const useCountryAllowlistAutocomplete = (): HookUseCountryAllowlistAutocompleteInterface => {
  const { t } = useTranslation('settings');
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { countries, pending: pendingCountries },
    functions: { filterCountriesByISOCode },
  } = useCountries();

  const {
    state: { countryAllowlist, pending: pendingCountryAllowlist },
    functions: {
      createCountryAllowlist,
      mapRestCountriesToCountryAllowlistData,
      mapCountryAllowlistToISOCodesArray,
    },
  } = useCountryAllowlist();

  const [value, setValue] = useState<RestCountryInterface[]>([]);

  const handleChange = (_: SyntheticEvent, value: RestCountryInterface[]): void => {
    setValue(value);
  };

  const handleSelectAll = (): void => setValue(countries);

  const handleSubmit = async (): Promise<void> => {
    const countriesAllowlist = mapRestCountriesToCountryAllowlistData(value);
    await createCountryAllowlist({ countriesAllowlist });

    enqueueSnackbar(t('Country allowlist has been successfully updated'), { variant: 'success' });
  };

  useEffect(() => {
    if (countryAllowlist.length === 0) return;

    const ISOCodes = mapCountryAllowlistToISOCodesArray(countryAllowlist);
    const countries = filterCountriesByISOCode(ISOCodes);

    setValue(countries);
  }, [countryAllowlist, mapCountryAllowlistToISOCodesArray, filterCountriesByISOCode]);

  const disabled = pendingCountryAllowlist || pendingCountries || countries.length === 0;

  return {
    state: {
      value,
      options: countries,
      disabled,
      pending: pendingCountryAllowlist,
    },
    handlers: {
      handleChange,
      handleSelectAll,
      handleSubmit,
    },
  };
};

export default useCountryAllowlistAutocomplete;
