import { useEffect } from 'react';
import { OptionsObject, useSnackbar } from 'notistack';

const useSnackbarErrorMessage = (
  message: string | null | undefined,
  options?: OptionsObject,
): void => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (message !== null && message !== undefined && message !== '') {
      enqueueSnackbar({ message, variant: 'error', preventDuplicate: true, ...options });
    }
  }, [message, options, enqueueSnackbar]);
};

export default useSnackbarErrorMessage;
