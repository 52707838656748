import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '& .SnackbarContent-root': {
        '& .SnackbarItem-action': {
          position: 'absolute',
          right: '12px',
          top: '4px',
        },

        '& .SnackbarItem-message': {
          paddingRight: 32,
        },

        '&[class*="contentRoot"]': {
          backgroundColor: theme.palette.primary.main,
        },

        '&[class*="variantSuccess"]': {
          backgroundColor: theme.palette.success.main,
        },

        '&[class*="variantError"]': {
          backgroundColor: theme.palette.error.main,
        },

        '&[class*="variantInfo"]': {
          backgroundColor: theme.palette.info.main,
        },

        '&[class*="variantWarning"]': {
          backgroundColor: theme.palette.warning.main,
        },
      },
    }),
  },
};
