import { FC } from 'react';

import { ReactComponent as BusyBeesLogo } from '@assets/icons/busybees-logo.svg';
import { Link } from './Logo.styles';

const Logo: FC = () => {
  return (
    <Link to="/">
      <BusyBeesLogo width={128} />
    </Link>
  );
};

export default Logo;
