import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack } from '@mui/material';

import {
  CategoryCard,
  DialogCreateSubcategory,
  DialogUpdateCategory,
  DialogUpdateSubcategory,
  SubcategoryCards,
} from './components';
import { useManageDialog } from '@hooks';
import { useAppDispatch, useAppSelector } from '@config/redux-toolkit';
import { selectCategory } from '@services/store/category';
import { SubcategoryInterface } from '@types-declaration/entities.types';
import { setSubcategoryAction } from '@services/store/subcategory';

const CategoryDetailsSection: FC = () => {
  const { t } = useTranslation('category-details');

  const dispatch = useAppDispatch();
  const category = useAppSelector(selectCategory);

  const {
    state: { open: dialogUpdateCategoryOpen },
    handlers: {
      handleOpen: handleDialogUpdateCategoryOpen,
      handleClose: handleDialogUpdateCategoryClose,
    },
  } = useManageDialog();

  const {
    state: { open: dialogCreateSubcategoryOpen },
    handlers: {
      handleOpen: handleDialogCreateSubcategoryOpen,
      handleClose: handleDialogCreateSubcategoryClose,
    },
  } = useManageDialog();

  const {
    state: { open: dialogUpdateSubcategoryOpen },
    handlers: {
      handleOpen: openDialogUpdateSubcategory,
      handleClose: handleDialogUpdateSubcategoryClose,
    },
  } = useManageDialog();

  if (category === null) return null;

  const handleSubcategoryClick = (subcategory: SubcategoryInterface): void => {
    dispatch(setSubcategoryAction(subcategory));
    openDialogUpdateSubcategory();
  };

  return (
    <>
      <Box component="section" mb={4} mx={4}>
        <CategoryCard />
        <Stack direction="row" spacing={2} mt={2}>
          <Button variant="contained" onClick={handleDialogUpdateCategoryOpen}>
            {t('Update Category')}
          </Button>
          <Button variant="outlined" onClick={handleDialogCreateSubcategoryOpen}>
            {t('Create Subcategory')}
          </Button>
        </Stack>
        <SubcategoryCards onClick={handleSubcategoryClick} />
      </Box>
      <DialogUpdateCategory
        open={dialogUpdateCategoryOpen}
        onClose={handleDialogUpdateCategoryClose}
      />
      <DialogCreateSubcategory
        open={dialogCreateSubcategoryOpen}
        onClose={handleDialogCreateSubcategoryClose}
      />
      <DialogUpdateSubcategory
        open={dialogUpdateSubcategoryOpen}
        onClose={handleDialogUpdateSubcategoryClose}
      />
    </>
  );
};

export default CategoryDetailsSection;
