import { FC, ReactNode } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

import { LoadingIndicator } from './LoadingButton.styles';

type Props = {
  children: ReactNode;
  loading: boolean;
} & ButtonProps;

const LoadingButton: FC<Props> = (props) => {
  const { children, loading, disabled, ...rest } = props;

  const loadingIndicator = loading ? (
    <LoadingIndicator>
      <CircularProgress color="inherit" size={16} />
    </LoadingIndicator>
  ) : null;

  return (
    <Button {...rest} disabled={loading || disabled}>
      {loadingIndicator}
      {children}
    </Button>
  );
};

export default LoadingButton;
