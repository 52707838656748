export enum UserRole {
  CLIENT = 'CLIENT',
  PRO = 'PRO',
  ADMIN = 'ADMIN',
}

export enum TenderStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum QueryParam {
  PAGE = 'page',
  TAB = 'tab',
  SEARCH = 'search',
  SORT_BY = 'sortBy',
  SORT_DIRECTION = 'sortDirection',
}

export enum TenderDetailsPageTabs {
  GENERAL = 'GENERAL',
  OFFERS = 'OFFERS',
}

export enum ProfileDetailsPageTabs {
  GENERAL = 'GENERAL',
  REVIEWS = 'REVIEWS',
  ADDITIONAL = 'ADDITIONAL',
}

export enum SwipeDirection {
  NONE = 'NONE',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  UP = 'UP',
  DOWN = 'DOWN',
}

export enum CloudinaryFolder {
  CATEGORIES = 'categories',
}

export enum StatisticsSelect {
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_THREE_MONTH = 'LAST_THREE_MONTH',
  LAST_SIX_MONTH = 'LAST_SIX_MONTH',
}

export enum UserVerificationDocument {
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
}

export enum PaymentMethod {
  CARD = 'card',
  PAYPAL = 'paypal',
  GOOGLE_PAY = 'google_pay',
  APPLE_PAY = 'apple_pay',
  EXTERNAL_PAY_SAFE_CARD = 'external_paysafecard',
}
