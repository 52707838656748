import { styled, Box } from '@mui/material';

export const ImageWrapper = styled(Box)({
  position: 'relative',
  width: 400,
  height: 200,
  overflow: 'hidden',
  borderRadius: 8,

  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});
