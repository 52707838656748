import { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tabs as MuiTabs, Tab } from '@mui/material';

import { TenderStatus } from '@enums/common.enums';
import { QUERY_PARAM_TAB_DEFAULT_VALUE } from '@constants/common.constants';

type Props = {
  value: string;
  onChange: (_: SyntheticEvent<Element, Event>, value: string) => void;
};

const Tabs: FC<Props> = (props) => {
  const { value, onChange } = props;

  const { t } = useTranslation('tenders');

  return (
    <Box component="section">
      <MuiTabs
        value={value}
        onChange={onChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ mb: 2 }}
      >
        <Tab value={QUERY_PARAM_TAB_DEFAULT_VALUE} label={t('All')} />
        <Tab value={TenderStatus.NEW} label={t('New')} />
        <Tab value={TenderStatus.ACCEPTED} label={t('Accepted')} />
        <Tab value={TenderStatus.COMPLETED} label={t('Completed')} />
        <Tab value={TenderStatus.CANCELED} label={t('Canceled')} />
      </MuiTabs>
    </Box>
  );
};

export default Tabs;
