import { AppStoreType } from '@config/redux-toolkit';
import { GetTenderWithRelationsByIdResponseType } from '@services/api/tender/tender.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const selectTender = (
  state: AppStoreType,
): GetTenderWithRelationsByIdResponseType | null => {
  return state.tenderReducer.tender;
};

export const selectTenderPending = (state: AppStoreType): boolean => {
  return state.tenderReducer.pending;
};

export const selectTenderError = (state: AppStoreType): ResponseFailureInterface | null => {
  return state.tenderReducer.error;
};
