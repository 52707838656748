import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, FieldArrayWithId, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { createSubcategoryThunk } from '@services/store/subcategories';
import { selectCategory } from '@services/store/category';
import { locales } from '@config/i18n';
import { useAppDispatch, useAppSelector } from '@config/redux-toolkit';
import { CreateSubcategoryTranslationBodyInterface } from '@services/api/subcategory/subcategory.types';

interface CreateSubcategoryFieldValuesInterface {
  translations: CreateSubcategoryTranslationBodyInterface[];
}

interface HookUseCreateSubcategoryArgumentsInterface {
  onSuccessSubmit: () => void;
}

interface HookUseCreateSubcategoryReturnInterface {
  state: {
    fields: FieldArrayWithId<CreateSubcategoryFieldValuesInterface, 'translations', 'id'>[];
    control: Control<CreateSubcategoryFieldValuesInterface, any>;
    pending: boolean;
  };
  handlers: {
    handleSubmit: (event: FormEvent) => void;
  };
}

const useCreateSubcategory = (
  args: HookUseCreateSubcategoryArgumentsInterface,
): HookUseCreateSubcategoryReturnInterface => {
  const { onSuccessSubmit } = args;

  const { t } = useTranslation('category-details');
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const category = useAppSelector(selectCategory);

  const { control, handleSubmit } = useForm<CreateSubcategoryFieldValuesInterface>();
  const { fields, append, remove } = useFieldArray({ control, name: 'translations' });

  const [pending, setPending] = useState<boolean>(false);

  const submit: SubmitHandler<CreateSubcategoryFieldValuesInterface> = ({ translations }) => {
    if (category !== null) {
      setPending(true);
      dispatch(createSubcategoryThunk({ body: { translations, categoryId: category.id } }))
        .then(() => {
          onSuccessSubmit();
          enqueueSnackbar({
            message: t('You have successfully created subcategory'),
            variant: 'success',
          });
          remove();
          locales.forEach((locale) => append({ locale, name: '' }));
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  useEffect(() => {
    locales.forEach((locale) => append({ locale, name: '' }));
  }, [append, remove]);

  return {
    state: {
      fields,
      control,
      pending,
    },
    handlers: {
      handleSubmit: handleSubmit(submit),
    },
  };
};

export default useCreateSubcategory;
