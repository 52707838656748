import authAxiosInstance from '@config/axios/auth-instance';
import {
  CreateCategoryArgumentsInterface,
  CreateCategoryResponseType,
  GetAllCategoriesArgumentsInterface,
  GetAllCategoriesResponseType,
  GetCategoryByIdArgumentsInterface,
  GetCategoryByIdResponseType,
  GetSubcategoriesByCategoryIdArgumentsInterface,
  GetSubcategoriesByCategoryIdResponseType,
  UpdateCategoryArgumentsInterface,
  UpdateCategoryResponseType,
} from './category.types';

export const getAllCategoriesRequest = async (
  args: GetAllCategoriesArgumentsInterface,
): Promise<GetAllCategoriesResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get('/categories', { params: query });
};

export const createCategoryRequest = async (
  args: CreateCategoryArgumentsInterface,
): Promise<CreateCategoryResponseType> => {
  const { body } = args;

  return await authAxiosInstance.post('/categories', body);
};

export const updateCategoryRequest = async (
  args: UpdateCategoryArgumentsInterface,
): Promise<UpdateCategoryResponseType> => {
  const { params, body } = args;

  return await authAxiosInstance.put(`/categories/${params.id}`, body);
};

export const getCategoryByIdRequest = async (
  args: GetCategoryByIdArgumentsInterface,
): Promise<GetCategoryByIdResponseType> => {
  const { params } = args;

  return await authAxiosInstance.get(`/categories/${params.id}`);
};

export const getSubcategoriesByCategoryIdRequest = async (
  args: GetSubcategoriesByCategoryIdArgumentsInterface,
): Promise<GetSubcategoriesByCategoryIdResponseType> => {
  const { params } = args;

  return await authAxiosInstance.get(`/categories/${params.categoryId}/subcategories`);
};
