import { configureStore } from '@reduxjs/toolkit';

import authReducer, { authLocalStorageMiddleware } from './auth';
import categoriesReducer from './categories';
import categoryReducer from './category';
import profileReducer from './profile';
import subcategoriesReducer from './subcategories';
import subcategoryReducer from './subcategory';
import tendersReducer from './tenders';
import tenderReducer from './tender';
import userReviewsReducer from './user-reviews';
import userVerificationsReducer from './user-verifications';
import usersReducer from './users';

export const store = configureStore({
  reducer: {
    authReducer,
    categoriesReducer,
    categoryReducer,
    profileReducer,
    subcategoriesReducer,
    subcategoryReducer,
    tendersReducer,
    tenderReducer,
    userReviewsReducer,
    userVerificationsReducer,
    usersReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(authLocalStorageMiddleware),
});
