import { AppStoreType } from '@config/redux-toolkit';
import { ResponseFailureInterface } from '@types-declaration/common.types';
import { UserInterface } from '@types-declaration/entities.types';

export const selectUser = (state: AppStoreType): UserInterface | null => {
  return state.authReducer.user;
};

export const selectUserPending = (state: AppStoreType): boolean => {
  return state.authReducer.pending;
};

export const selectUserError = (state: AppStoreType): ResponseFailureInterface | null => {
  return state.authReducer.error;
};
