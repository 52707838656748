import { AppStoreType } from '@config/redux-toolkit';
import { GetAllUsersResponseType } from '@services/api/user/user.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const selectUsers = (state: AppStoreType): GetAllUsersResponseType => {
  return state.usersReducer.users;
};

export const selectUsersPending = (state: AppStoreType): boolean => {
  return state.usersReducer.pending;
};

export const selectUsersError = (state: AppStoreType): ResponseFailureInterface | null => {
  return state.usersReducer.error;
};
