import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, ListItemText, ListItem, ListItemIcon } from '@mui/material';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { format } from 'date-fns';

import { UserEducationInterface } from '@types-declaration/entities.types';

type Props = {
  educations: UserEducationInterface[];
};

const ProfileEducations: FC<Props> = (props) => {
  const { educations } = props;

  const { t } = useTranslation('profile-details');

  if (educations.length === 0) return null;

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle3" color="text.secondary">
        {t('Educations')}
      </Typography>
      <Stack spacing={1} mt={2}>
        {educations.map(({ id, school, degree, fieldOfStudy, start, end }) => (
          <ListItem disablePadding key={id}>
            <ListItemIcon>
              <SchoolOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={[school, degree, fieldOfStudy].filter(Boolean).join(' - ')}
              secondary={
                Boolean(start) &&
                Boolean(end) &&
                [format(new Date(start), 'dd MMM yyyy'), format(new Date(end), 'dd MMM yyyy')].join(
                  ' - ',
                )
              }
            />
          </ListItem>
        ))}
      </Stack>
    </Stack>
  );
};

export default ProfileEducations;
