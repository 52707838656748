import { green, grey, khaki, red, yellow } from '@config/mui/colors';

const PRIMARY = {
  light: green[70],
  main: green[40],
  dark: green[30],
  contrastText: '#fff',
};

const SECONDARY = {
  light: grey[70],
  main: grey[40],
  dark: grey[30],
  contrastText: '#fff',
};

const ERROR = {
  light: red[70],
  main: red[40],
  dark: red[30],
  contrastText: '#fff',
};

const WARNING = {
  light: yellow[90],
  main: yellow[80],
  dark: yellow[70],
  contrastText: '#000',
};

const INFO = {
  light: khaki[70],
  main: khaki[40],
  dark: khaki[30],
  contrastText: '#fff',
};

const GREY = {
  ...grey,
};

const TEXT = {
  primary: green[10],
  secondary: khaki[30],
};

export default {
  primary: PRIMARY,
  secondary: SECONDARY,
  error: ERROR,
  warning: WARNING,
  info: INFO,
  grey: GREY,
  text: TEXT,
};
