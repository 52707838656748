import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createCategoryRequest,
  getAllCategoriesRequest,
} from '@services/api/category/category.api';
import {
  CreateCategoryArgumentsInterface,
  CreateCategoryResponseType,
  GetAllCategoriesArgumentsInterface,
  GetAllCategoriesResponseType,
} from '@services/api/category/category.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const getAllCategoriesThunk = createAsyncThunk<
  GetAllCategoriesResponseType,
  GetAllCategoriesArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('categories/getAllCategoriesThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await getAllCategoriesRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});

export const createCategoryThunk = createAsyncThunk<
  CreateCategoryResponseType,
  CreateCategoryArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('categories/createCategoryThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await createCategoryRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});
