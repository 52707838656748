import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './user-verifications.state';
import { getUnverifiedUserVerificationsThunk } from './user-verifications.actions';

export const userVerificationsSlice = createSlice({
  name: 'user-verifications',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUnverifiedUserVerificationsThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getUnverifiedUserVerificationsThunk.fulfilled, (state, action) => {
        state.pending = false;
        state.userVerifications = action.payload;
      })
      .addCase(getUnverifiedUserVerificationsThunk.rejected, (state, action) => {
        state.pending = false;
        state.error = action.payload || null;
      });
  },
});

export default userVerificationsSlice.reducer;
