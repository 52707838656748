import { styled, Box } from '@mui/system';

export const LoadingIndicator = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  backgroundColor: theme.palette.grey[90],
}));
