import { AppStoreType } from '@config/redux-toolkit';

import { GetProfileByIdResponseType } from '@services/api/user/user.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const selectProfile = (state: AppStoreType): GetProfileByIdResponseType | null => {
  return state.profileReducer.profile;
};

export const selectProfilePending = (state: AppStoreType): boolean => {
  return state.profileReducer.pending;
};

export const selectProfileError = (state: AppStoreType): ResponseFailureInterface | null => {
  return state.profileReducer.error;
};
