import { useCallback, useEffect, useState } from 'react';
import { subDays, subMonths } from 'date-fns';
import { useSnackbar } from 'notistack';
import { SelectChangeEvent } from '@mui/material';

import { StatisticsSelect } from '@enums/common.enums';
import { GetUserStatisticsResponseType } from '@services/api/user/user.types';
import { getUserStatisticsRequest } from '@services/api/user/user.api';
import { ResponseFailureInterface } from '@types-declaration/common.types';
import { GetTenderStatisticsResponseType } from '@services/api/tender/tender.types';
import { getTenderStatisticsRequest } from '@services/api/tender/tender.api';

interface HookUseStatisticsReturnInterface {
  state: {
    select: StatisticsSelect;
    from: Date;
    userStatistics: GetUserStatisticsResponseType | null;
    tenderStatistics: GetTenderStatisticsResponseType | null;
  };
  handlers: {
    handleSelectChange: (event: SelectChangeEvent) => void;
  };
}

const useStatistics = (): HookUseStatisticsReturnInterface => {
  const { enqueueSnackbar } = useSnackbar();

  const [select, setSelect] = useState<StatisticsSelect>(StatisticsSelect.LAST_SIX_MONTH);
  const [from, setFrom] = useState<Date>(subMonths(new Date(), 6));
  const [userStatistics, setUserStatistics] = useState<GetUserStatisticsResponseType | null>(null);
  const [tenderStatistics, setTenderStatistics] = useState<GetTenderStatisticsResponseType | null>(
    null,
  );

  const getUserStatistics = useCallback(async () => {
    try {
      setUserStatistics(await getUserStatisticsRequest({ query: { from, to: new Date() } }));
    } catch (error) {
      const { message } = error as ResponseFailureInterface;

      if (message !== null) {
        enqueueSnackbar(message, { variant: 'error', preventDuplicate: true });
      }
    }
  }, [from, enqueueSnackbar]);

  const getTenderStatistics = useCallback(async () => {
    try {
      setTenderStatistics(await getTenderStatisticsRequest({ query: { from, to: new Date() } }));
    } catch (error) {
      const { message } = error as ResponseFailureInterface;

      if (message !== null) {
        enqueueSnackbar(message, { variant: 'error', preventDuplicate: true });
      }
    }
  }, [from, enqueueSnackbar]);

  const handleSelectChange = (event: SelectChangeEvent): void => {
    const value = event.target.value as StatisticsSelect;
    setSelect(value);

    switch (value) {
      case StatisticsSelect.LAST_WEEK:
        return setFrom(subDays(new Date(), 7));
      case StatisticsSelect.LAST_MONTH:
        return setFrom(subMonths(new Date(), 1));
      case StatisticsSelect.LAST_THREE_MONTH:
        return setFrom(subMonths(new Date(), 3));
      case StatisticsSelect.LAST_SIX_MONTH:
        return setFrom(subMonths(new Date(), 6));
      default:
        return;
    }
  };

  useEffect(() => {
    getUserStatistics();
    getTenderStatistics();
  }, [getUserStatistics, getTenderStatistics]);

  return {
    state: {
      select,
      from,
      userStatistics,
      tenderStatistics,
    },
    handlers: {
      handleSelectChange,
    },
  };
};

export default useStatistics;
