import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';

import { useDataGridColumns, useProfileReviews } from '@pages/ProfileDetails/hooks';
import { useAppSelector } from '@config/redux-toolkit';
import { selectUserReviews, selectUserReviewsPending } from '@services/store/user-reviews';
import { GET_USER_REVIEWS_SIZE_OPTIONS } from '@constants/common.constants';

const ProfileReviews: FC = () => {
  const { t } = useTranslation('profile-details');

  const userReviews = useAppSelector(selectUserReviews);
  const pending = useAppSelector(selectUserReviewsPending);

  const {
    state: { queryParams },
    handlers: { handleSortModelChange, handlePaginationModelChange },
  } = useProfileReviews();

  const columns = useDataGridColumns();

  return (
    <DataGrid
      rows={userReviews.data}
      rowCount={userReviews.totalRecords}
      columns={columns}
      loading={pending}
      sortingMode="server"
      paginationMode="server"
      disableRowSelectionOnClick
      pageSizeOptions={GET_USER_REVIEWS_SIZE_OPTIONS}
      onSortModelChange={handleSortModelChange}
      onPaginationModelChange={handlePaginationModelChange}
      paginationModel={{ pageSize: Number(queryParams.take), page: Number(queryParams.page) - 1 }}
      slotProps={{
        pagination: {
          labelRowsPerPage: t('Rows per page:'),
          labelDisplayedRows: ({ from, to, count }) => `${from}–${to} / ${count}`,
        },
      }}
    />
  );
};

export default ProfileReviews;
