import { styled, Box } from '@mui/material';

export const Root = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  gap: 8,
  overflowX: 'auto',
});

export const ImageWrapper = styled(Box)({
  cursor: 'pointer',
});
