import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import LoadingButton from '@components/LoadingButton';
import { useAcceptUserVerification } from '../../hooks';
import { UserVerificationInterface } from '@types-declaration/entities.types';

type Props = {
  ns: string;
  id: string;
  open: boolean;
  onClose: () => void;
  onSuccessSubmit?: (userVerification: UserVerificationInterface) => void;
};

const DialogAcceptUserVerification: FC<Props> = (props) => {
  const { ns, id, open, onClose, onSuccessSubmit } = props;

  const { t } = useTranslation(ns);

  const {
    state: { pending },
    handlers: { handleAcceptUserVerification },
  } = useAcceptUserVerification({ ns, id, onSuccessSubmit });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('Are you sure you want to APPROVE this verification inquiry?')}</DialogTitle>
      <DialogContent>{t('Make sure you checked all the needed information')}</DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <LoadingButton variant="contained" loading={pending} onClick={handleAcceptUserVerification}>
          {t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAcceptUserVerification;
