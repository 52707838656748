import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { getTopRatedProsByReviewsRequest } from '@services/api/review/review.api';
import { GetTopRatedProsByReviewsResponseType } from '@services/api/review/review.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

interface HookUseTopRatedProsReturnInterface {
  reviews: GetTopRatedProsByReviewsResponseType;
}

const useTopRatedPros = (): HookUseTopRatedProsReturnInterface => {
  const { enqueueSnackbar } = useSnackbar();

  const [reviews, setReviews] = useState<GetTopRatedProsByReviewsResponseType>([]);

  const getTopRatedPros = useCallback(async () => {
    try {
      setReviews(await getTopRatedProsByReviewsRequest());
    } catch (error) {
      const { message } = error as ResponseFailureInterface;

      if (message !== null) {
        enqueueSnackbar(message, { variant: 'error', preventDuplicate: true });
      }
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getTopRatedPros();
  }, [getTopRatedPros]);

  return {
    reviews,
  };
};

export default useTopRatedPros;
