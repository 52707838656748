import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteRenderInputParams, InputLabelProps, TextField } from '@mui/material';

const AutocompleteInput = (params: AutocompleteRenderInputParams): JSX.Element => {
  const { t } = useTranslation('settings');

  return (
    <TextField
      {...(params as AutocompleteRenderInputParams & {
        InputLabelProps: PropsWithChildren<InputLabelProps>;
      })}
      label={t('Select countries')}
    />
  );
};

export default AutocompleteInput;
