import { createAsyncThunk } from '@reduxjs/toolkit';

import { getProfileByIdRequest } from '@services/api/user/user.api';
import {
  GetProfileByIdArgumentsInterface,
  GetProfileByIdResponseType,
} from '@services/api/user/user.types';
import { ResponseFailureInterface } from '@types-declaration/common.types';

export const getProfileByIdThunk = createAsyncThunk<
  GetProfileByIdResponseType,
  GetProfileByIdArgumentsInterface,
  { rejectValue: ResponseFailureInterface }
>('profile/getProfileByIdThunk', async (args, { fulfillWithValue, rejectWithValue }) => {
  try {
    return fulfillWithValue(await getProfileByIdRequest(args));
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});
