import { styled, Box } from '@mui/material';

export const TenderCreator = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  marginBottom: 16,
  cursor: 'pointer',
});

export const Card = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
});
